import axios from "axios";

const baseApi = axios.create({
  // baseURL: "https://api.muhsip.com/",

  baseURL: "https://api.dijital-dental.com.tr",
});

baseApi.interceptors.request.use(
  (config) => {
    const data = localStorage.getItem("authUser");
    const token = JSON.parse(data)?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default baseApi;
