import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

import NewUserImg from "../../assets/images/dent/newuser.png";
import OldUserImg from "../../assets/images/dent/olduser.png";
import { useNavigate } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const Home = () => {
  const navigate = useNavigate();

  const [dCode, setDCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(6, "Doktor kodu 6 haneden oluşmalıdır.")
        .max(6)
        .required("Lütfen doktor kodu giriniz"),
    }),
    onSubmit: (values) => {
      setDCode(values.code);
    },
  });

  if (!dCode) {
    return (
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "#FDFDFD",
        }}
      >
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6}>
                <div className="text-center mb-5">
                  <h1>LOGO</h1>
                </div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <div className="mb-3">
                    <Label htmlFor="code" className="form-label">
                      Doktor Kodu
                    </Label>
                    <Input
                      name="code"
                      className="form-control"
                      placeholder="Doktor Kodu giriniz"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.code || ""}
                      maxLength={6}
                      invalid={
                        validation.touched.code && validation.errors.code
                          ? true
                          : false
                      }
                    />
                    {validation.touched.code && validation.errors.code && (
                      <FormFeedback type="invalid">
                        {validation.errors.code}
                      </FormFeedback>
                    )}
                  </div>

                  <div className="mt-4">
                    {!loading ? (
                      <Button
                        color="danger"
                        className="btn btn-danger w-100"
                        type="submit"
                      >
                        Giriş Yap
                      </Button>
                    ) : (
                      <Button
                        color="danger"
                        disabled={error ? null : loading ? true : false}
                        className="btn btn-danger w-100"
                      >
                        <Spinner size="sm" className="me-2" /> Yükleniyor
                      </Button>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0" style={{ color: "#808080" }}>
                    &copy; {new Date().getFullYear()} BlaBla{" "}
                    <i className="mdi mdi-heart text-danger"></i> by Yusuf Ayaz
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }

  return (
    <div
      className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
      style={{
        background: "#FDFDFD",
      }}
    >
      <div className="auth-page-content overflow-hidden pt-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="text-center mb-5">
                <h1>LOGO</h1>
              </div>
              <Card
                className="card-animate"
                style={{ cursor: "pointer", border: "1px solid red" }}
                onClick={() =>
                  navigate("/new-patient", {
                    state: { doctorCode: dCode },
                  })
                }
              >
                <CardBody>
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h2 className="mt-4 ff-secondary fw-semibold text-danger">
                        Yeni Hasta Girişi
                      </h2>
                    </div>
                    {/* <img
                      src={NewUserImg}
                      className="me-3 rounded-circle avatar-md p-2 bg-light"
                      alt="newUser"
                    /> */}
                  </div>
                </CardBody>
              </Card>
              <Card
                className="card-animate"
                style={{ cursor: "pointer", border: "1px solid red" }}
                onClick={() =>
                  navigate("/patient-login", {
                    state: { doctorCode: dCode },
                  })
                }
              >
                <CardBody>
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h2 className="mt-4 ff-secondary fw-semibold text-danger">
                        Mevcut Hasta Girişi
                      </h2>
                      <p className="fw-semibold text-muted fs-11">
                        *Daha önce muayene kaydı olan hastalarımız için.
                      </p>
                    </div>
                    {/* <img
                      src={OldUserImg}
                      className="me-3 rounded-circle avatar-md p-2 bg-light"
                      alt="oldUser"
                    /> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <footer className="footer">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <p className="mb-0" style={{ color: "#808080" }}>
                  &copy; {new Date().getFullYear()} BlaBla{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Yusuf Ayaz
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default withRouter(Home);
