import React, { useEffect, useState } from "react";
import { Button, Input, Form, Label } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";

// data dosyaları
const questionList = [
  {
    id: 0,
    question: "Diş tedavisinden korkuyor musunuz?",
    required: true,
    type: 4,
    answers: null,
    dependencies: null,
  },
  {
    id: 1,
    question: "Hangi hastalıklara sahipsiniz?",
    required: false,
    type: 5,
    answers: [
      { value: "Kalp Hastalıkları", label: "Kalp Hastalıkları" },
      { value: "Şeker Hastalığı", label: "Şeker Hastalığı" },
      { value: "Tansiyon Sorunu", label: "Tansiyon Sorunu" },
      { value: "Epilepsi (Sara)", label: "Epilepsi (Sara)" },
      { value: "Ateşli Romatizma", label: "Ateşli Romatizma" },
      { value: "Eklem Romatizması", label: "Eklem Romatizması" },
      { value: "Guatr (Triod tabletleri)", label: "Guatr (Triod tabletleri)" },
      { value: "Kan Hastalıkları", label: "Kan Hastalıkları" },
      { value: "İlaç Alerjisi", label: "İlaç Alerjisi" },
      { value: "Zührevi Hastalık", label: "Zührevi Hastalık" },
      { value: "Sarılık", label: "Sarılık" },
      { value: "Astım", label: "Astım" },
      { value: "Saman Nezlesi", label: "Saman Nezlesi" },
      {
        value: "Böbrek Karaciğer Bozuklukları",
        label: "Böbrek Karaciğer Bozuklukları",
      },
      { value: "Akciğer Hastalıkları", label: "Akciğer Hastalıkları" },
      { value: "Sinüzit", label: "Sinüzit" },
      { value: "Anjina Pektoris", label: "Anjina Pektoris" },
      { value: "Bulaşıcı Hastalıklar", label: "Bulaşıcı Hastalıklar" },
      { value: "Alerjiler", label: "Alerjiler" },
      { value: "Felçler", label: "Felçler" },
    ],
    dependencies: null,
  },
  {
    id: 2,
    question: "Kalp hastalıkları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kalp Hastalıkları",
    },
  },
  {
    id: 3,
    question: "Kalp hastalıkları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kalp Hastalıkları",
    },
  },
  {
    id: 4,
    question: "Şeker hastalığı için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Şeker Hastalığı",
    },
  },
  {
    id: 5,
    question: "Şeker hastalığı için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Şeker Hastalığı",
    },
  },
  {
    id: 51,
    question: "Şeker hastalığı için tedavi görüyor musunuz2?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Şeker Hastalığı",
    },
  },
  {
    id: 6,
    question: "Tansiyon sorunu için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Tansiyon Sorunu",
    },
  },
  {
    id: 7,
    question: "Tansiyon sorunu için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Tansiyon Sorunu",
    },
  },
  {
    id: 8,
    question: "Epilepsi için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Epilepsi (Sara)",
    },
  },
  {
    id: 9,
    question: "Epilepsi için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Epilepsi (Sara)",
    },
  },
  {
    id: 10,
    question: "Ateşli romatizma için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Ateşli Romatizma",
    },
  },
  {
    id: 11,
    question: "Ateşli romatizma için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Ateşli Romatizma",
    },
  },
  {
    id: 12,
    question: "Eklem romatizması için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Eklem Romatizması",
    },
  },
  {
    id: 13,
    question: "Eklem romatizması için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Eklem Romatizması",
    },
  },
  {
    id: 14,
    question: "Guatr için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Guatr (Triod tabletleri)",
    },
  },
  {
    id: 15,
    question: "Guatr için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Guatr (Triod tabletleri)",
    },
  },
  {
    id: 16,
    question: "Kan hastalıkları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kan Hastalıkları",
    },
  },
  {
    id: 17,
    question: "Kan hastalıkları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kan Hastalıkları",
    },
  },
  {
    id: 18,
    question: "İlaç alerjisi için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "İlaç Alerjisi",
    },
  },
  {
    id: 19,
    question: "İlaç alerjisi için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "İlaç Alerjisi",
    },
  },
  {
    id: 20,
    question: "Zührevi hastalık için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Zührevi Hastalık",
    },
  },
  {
    id: 21,
    question: "Zührevi hastalık için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Zührevi Hastalık",
    },
  },
  {
    id: 22,
    question: "Sarılık için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sarılık",
    },
  },
  {
    id: 23,
    question: "Sarılık için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sarılık",
    },
  },
  {
    id: 24,
    question: "Astım için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Astım",
    },
  },
  {
    id: 25,
    question: "Astım için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Astım",
    },
  },
  {
    id: 26,
    question: "Saman nezlesi için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Saman Nezlesi",
    },
  },
  {
    id: 27,
    question: "Saman nezlesi için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Saman Nezlesi",
    },
  },
  {
    id: 28,
    question: "Böbrek karaciğer bozuklukları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Böbrek Karaciğer Bozuklukları",
    },
  },
  {
    id: 29,
    question: "Böbrek karaciğer bozuklukları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Böbrek Karaciğer Bozuklukları",
    },
  },
  {
    id: 30,
    question: "Akciğer hastalıkları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Akciğer Hastalıkları",
    },
  },
  {
    id: 31,
    question: "Akciğer hastalıkları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Akciğer Hastalıkları",
    },
  },
  {
    id: 32,
    question: "Sinüzit için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sinüzit",
    },
  },
  {
    id: 33,
    question: "Sinüzit için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sinüzit",
    },
  },
  {
    id: 34,
    question: "Anjina pektoris için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Anjina Pektoris",
    },
  },
  {
    id: 35,
    question: "Anjina pektoris için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Anjina Pektoris",
    },
  },
  {
    id: 36,
    question: "Bulaşıcı hastalıklar için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Bulaşıcı Hastalıklar",
    },
  },
  {
    id: 37,
    question: "Bulaşıcı hastalıklar için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Bulaşıcı Hastalıklar",
    },
  },
  {
    id: 38,
    question: "Alerjiler için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Alerjiler",
    },
  },
  {
    id: 39,
    question: "Alerjiler için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Alerjiler",
    },
  },
  {
    id: 40,
    question: "Felçler için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Felçler",
    },
  },
  {
    id: 41,
    question: "Felçler için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Felçler",
    },
  },
  {
    id: 42,
    question: "Lokal anestezi altında tedavi olmak ister misiniz?",
    required: true,
    type: 3,
    answers: [
      {
        value: "Hayır, yerel bir anestezi istemiyorum.",
        label: "Hayır, yerel bir anestezi istemiyorum.",
      },
      { value: "Evet, her zaman.", label: "Evet, her zaman." },
      {
        value: "Sadece ağır prosedürler için.",
        label: "Sadece ağır prosedürler için.",
      },
    ],
    dependencies: null,
  },
  {
    id: 48,
    question:
      "Geçmişte dişte yeterli anestezi sağlamak için birkaç anestezik enjeksiyonun gerekli olduğu deneyimini yaşadınız mı?",
    required: true,
    type: 0,
    answers: null,
    dependencies: null,
  },
];

const Test = () => {
  const [groupQuest, setGroupQuest] = useState([]);

  const [questions, setQuestions] = useState([]);
  const [questionsResult, setQuestionsResult] = useState([]);
  const [visibleQuestions, setVisibleQuestions] = useState(
    questionList.filter((q) => !q.dependencies).map((q) => q.id)
  );

  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = groupQuest.length - 1;

  const getValidationSchema = (visibleQuestions) => {
    return Yup.object(
      questionList.reduce((acc, question) => {
        const isQuestionVisible = visibleQuestions.includes(question.id);

        if (isQuestionVisible && question.required) {
          switch (question.type) {
            case 2:
              acc[question.id] = Yup.array()
                .of(Yup.string())
                .required("Zorunlu alan.");
              break;
            case 4:
              acc[question.id] = Yup.number().required("Zorunlu alan.");
              break;
            case 5:
              acc[question.id] = Yup.array()
                .of(Yup.string())
                .required("Zorunlu alan.");
              break;
            default:
              acc[question.id] = Yup.string().required("Zorunlu alan.");
              break;
          }
        } else {
          switch (question.type) {
            case 2:
              acc[question.id] = Yup.array().of(Yup.string());
              break;
            case 4:
              acc[question.id] = Yup.number();
              break;
            case 5:
              acc[question.id] = Yup.array().of(Yup.string());
              break;
            default:
              acc[question.id] = Yup.string();
              break;
          }
        }
        return acc;
      }, {})
    );
  };

  const questionValidation = useFormik({
    initialValues: questionList.reduce((acc, curr) => {
      switch (curr.type) {
        case 2:
          acc[curr.id] = [];
          break;
        case 4:
          acc[curr.id] = 1;
          break;
        case 5:
          acc[curr.id] = [];
          break;
        case 0:
          acc[curr.id] = "Hayır";
          break;
        default:
          acc[curr.id] = "";
          break;
      }
      return acc;
    }, {}),
    validationSchema: getValidationSchema(visibleQuestions),
    onSubmit: (values) => {
      // const filteredValues = Object.keys(values).reduce((acc, key) => {
      //   const questionId = parseInt(key);
      //   if (visibleQuestions.includes(questionId)) {
      //     acc[key] = {
      //       id: 0,
      //       userId: "",
      //       questionId: questionId,
      //       answer: values[key],
      //     };
      //   } else {
      //     acc[key] = {
      //       id: 0,
      //       userId: "",
      //       questionId: questionId,
      //       answer: "-",
      //     };
      //   }
      //   return acc;
      // }, {});

      // const resultsWithQuestions = questionList.map((question) => {
      //   const value = filteredValues[question.id];

      //   return {
      //     question: question.question,
      //     answer: value
      //       ? Array.isArray(value.answer)
      //         ? value.answer.join(", ")
      //         : value.answer
      //       : "-",
      //   };
      // });

      // setQuestions(filteredValues);
      // setQuestionsResult(resultsWithQuestions);
      // setTab(agreements.length == 0 ? 5 : 4);
      console.log(values);
    },
  });

  useEffect(() => {
    const updateVisibleQuestions = () => {
      const shouldQuestionBeVisible = (question) => {
        if (!question.dependencies) {
          return true;
        }

        const { questionId, answer } = question.dependencies;
        const parentAnswer = questionValidation.values[questionId];

        // Parent answer is an array
        if (Array.isArray(parentAnswer)) {
          return parentAnswer.includes(answer);
        }

        // Parent answer is a string
        return parentAnswer === answer;
      };

      const getVisibleQuestions = () => {
        const visible = new Set();

        const addVisibleQuestions = (questionId) => {
          const question = questionList.find((q) => q.id === questionId);
          if (!question || visible.has(questionId)) return;

          if (shouldQuestionBeVisible(question)) {
            visible.add(questionId);
            questionList.forEach((q) => {
              if (q.dependencies?.questionId === questionId) {
                addVisibleQuestions(q.id);
              }
            });
          }
        };

        questionList.forEach((q) => {
          if (!q.dependencies) {
            addVisibleQuestions(q.id);
          }
        });

        return Array.from(visible);
      };

      const newVisibleQuestions = getVisibleQuestions();
      setVisibleQuestions(newVisibleQuestions);
    };

    updateVisibleQuestions();
    questionValidation.validateForm();
  }, [questionValidation.values]);

  useEffect(() => {
    questionValidation.setErrors({});
    questionValidation.validateForm();
  }, [visibleQuestions]);

  useEffect(() => {
    const newGroupQuest = []; // Yeni grup oluşturmak için bir dizi
    const addedIds = new Set(); // Eklenmiş ID'leri takip etmek için Set

    visibleQuestions
      .map((questionId) => questionList.find((q) => q.id === questionId))
      .forEach((e) => {
        if (!addedIds.has(e.id)) {
          // Eğer ID daha önce eklenmemişse
          addedIds.add(e.id); // ID'yi ekle

          if (!e.dependencies) {
            const lastGroup = newGroupQuest[newGroupQuest.length - 1];
            if (lastGroup && lastGroup.dependencies === null) {
              if (lastGroup.items.length < 2) {
                lastGroup.items.push(e);
              } else {
                newGroupQuest.push({
                  dependencies: null,
                  items: [e],
                });
              }
            } else {
              newGroupQuest.push({
                dependencies: null,
                items: [e],
              });
            }
          } else {
            const answer = e.dependencies.answer;
            const existingGroup = newGroupQuest.find(
              (group) =>
                group.dependencies && group.dependencies.answer === answer
            );

            if (existingGroup) {
              existingGroup.items.push(e);
            } else {
              newGroupQuest.push({
                dependencies: e.dependencies,
                items: [e],
              });
            }
          }
        }
      });

    setGroupQuest(newGroupQuest); // Sonuçları duruma kaydet
  }, [visibleQuestions]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        questionValidation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="mt-4">
        {groupQuest[currentPage]?.items.map((data) => {
          if (!data) return null;

          if (data.type === 0) {
            // Radio buttons
            return (
              <div className="mb-3" key={data.id}>
                <Label htmlFor={data.id} className="form-label">
                  {data.dependencies ? (
                    <>
                      {data.question
                        .split(new RegExp(`(${data.dependencies.answer})`, "i"))
                        .map((part, index) =>
                          part.toLowerCase() ===
                          data.dependencies.answer.toLowerCase() ? (
                            <b key={index}>{part}</b>
                          ) : (
                            <span key={index}>{part}</span>
                          )
                        )}
                    </>
                  ) : (
                    data.question
                  )}
                </Label>
                <div className="form-check mb-2">
                  <Input
                    name={data.id.toString()}
                    id={`${data.id}1`}
                    className="form-check-input"
                    type="radio"
                    value="Evet"
                    checked={questionValidation.values[data.id] === "Evet"}
                    onChange={questionValidation.handleChange}
                    onBlur={questionValidation.handleBlur}
                  />
                  <Label className="form-check-label" htmlFor={`${data.id}1`}>
                    Evet
                  </Label>
                </div>
                <div className="form-check">
                  <Input
                    name={data.id.toString()}
                    id={`${data.id}2`}
                    className="form-check-input"
                    type="radio"
                    value="Hayır"
                    checked={
                      questionValidation.values[data.id] === "Hayır" ||
                      questionValidation.values[data.id] === undefined
                    }
                    onChange={questionValidation.handleChange}
                    onBlur={questionValidation.handleBlur}
                  />
                  <Label className="form-check-label" htmlFor={`${data.id}2`}>
                    Hayır
                  </Label>
                </div>
              </div>
            );
          } else if (data.type === 1) {
            // Text input
            return (
              <div className="mb-3" key={data.id}>
                <Label htmlFor={data.id} className="form-label">
                  {data.dependencies ? (
                    <>
                      {data.question
                        .split(new RegExp(`(${data.dependencies.answer})`, "i"))
                        .map((part, index) =>
                          part.toLowerCase() ===
                          data.dependencies.answer.toLowerCase() ? (
                            <b key={index}>{part}</b>
                          ) : (
                            <span key={index}>{part}</span>
                          )
                        )}
                    </>
                  ) : (
                    data.question
                  )}
                </Label>
                <Input
                  name={data.id.toString()}
                  id={data.id.toString()}
                  className="form-control"
                  placeholder={data.placeholder || ""}
                  type="text"
                  autoComplete="off"
                  onChange={questionValidation.handleChange}
                  onBlur={questionValidation.handleBlur}
                  value={questionValidation.values[data.id] || ""}
                  invalid={
                    questionValidation.touched[data.id] &&
                    !!questionValidation.errors[data.id]
                  }
                />
                {questionValidation.touched[data.id] &&
                  questionValidation.errors[data.id] && (
                    <FormFeedback type="invalid">
                      {questionValidation.errors[data.id]}
                    </FormFeedback>
                  )}
              </div>
            );
          } else if (data.type === 2) {
            // Multi-select dropdown
            return (
              <div className="mb-3" key={data.id}>
                <Label htmlFor={data.id} className="form-label">
                  {data.dependencies ? (
                    <>
                      {data.question
                        .split(new RegExp(`(${data.dependencies.answer})`, "i"))
                        .map((part, index) =>
                          part.toLowerCase() ===
                          data.dependencies.answer.toLowerCase() ? (
                            <b key={index}>{part}</b>
                          ) : (
                            <span key={index}>{part}</span>
                          )
                        )}
                    </>
                  ) : (
                    data.question
                  )}
                </Label>
                <Select
                  name={data.id.toString()}
                  options={data.answers}
                  isMulti
                  value={data.answers.filter((e) =>
                    (questionValidation.values[data.id] || []).includes(e.value)
                  )}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    questionValidation.setFieldValue(data.id, selectedValues);
                  }}
                  onBlur={questionValidation.handleBlur}
                  isClearable
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  placeholder="Bir veya birden fazla seçebilirsiniz..."
                  noOptionsMessage={() => "Sonuç bulunamadı!"}
                />
                {questionValidation.touched[data.id] &&
                  questionValidation.errors[data.id] && (
                    <FormFeedback
                      type="invalid"
                      className="d-block invalid-feedback"
                    >
                      {questionValidation.errors[data.id]}
                    </FormFeedback>
                  )}
              </div>
            );
          } else if (data.type === 3) {
            // Single-select dropdown
            return (
              <div className="mb-3" key={data.id}>
                <Label htmlFor={data.id} className="form-label">
                  {data.dependencies ? (
                    <>
                      {data.question
                        .split(new RegExp(`(${data.dependencies.answer})`, "i"))
                        .map((part, index) =>
                          part.toLowerCase() ===
                          data.dependencies.answer.toLowerCase() ? (
                            <b key={index}>{part}</b>
                          ) : (
                            <span key={index}>{part}</span>
                          )
                        )}
                    </>
                  ) : (
                    data.question
                  )}
                </Label>
                <Select
                  name={data.id.toString()}
                  options={data.answers}
                  value={data.answers.find(
                    (e) => e.value === questionValidation.values[data.id]
                  )}
                  onChange={(option) => {
                    questionValidation.setFieldValue(
                      data.id,
                      option ? option.value : ""
                    );
                  }}
                  onBlur={questionValidation.handleBlur}
                  placeholder="Bir seçenek seçin..."
                  noOptionsMessage={() => "Sonuç bulunamadı!"}
                />
                {questionValidation.touched[data.id] &&
                  questionValidation.errors[data.id] && (
                    <FormFeedback
                      type="invalid"
                      className="d-block invalid-feedback"
                    >
                      {questionValidation.errors[data.id]}
                    </FormFeedback>
                  )}
              </div>
            );
          } else if (data.type === 4) {
            // Range slider
            return (
              <div className="mb-3" key={data.id}>
                <Label
                  htmlFor={data.id}
                  className="form-label d-flex flex-column"
                >
                  {data.dependencies ? (
                    <>
                      {data.question
                        .split(new RegExp(`(${data.dependencies.answer})`, "i"))
                        .map((part, index) =>
                          part.toLowerCase() ===
                          data.dependencies.answer.toLowerCase() ? (
                            <b key={index}>{part}</b>
                          ) : (
                            <span key={index}>{part}</span>
                          )
                        )}
                    </>
                  ) : (
                    data.question
                  )}
                  <span className="text-muted fs-11">
                    Derece:{" "}
                    <b>
                      {questionValidation.values[data.id] == 1
                        ? "1 Korkmuyorum"
                        : questionValidation.values[data.id] == 2
                        ? "2 Ortalama korkuyorum"
                        : questionValidation.values[data.id] == 3
                        ? "3 Çok korkuyorum"
                        : questionValidation.values[data.id]}
                    </b>
                  </span>
                </Label>
                <input
                  name={data.id.toString()}
                  type="range"
                  className="form-range"
                  min="1"
                  max="3"
                  step="1"
                  onChange={questionValidation.handleChange}
                  onBlur={questionValidation.handleBlur}
                  value={questionValidation.values[data.id] || "1"}
                />
              </div>
            );
          } else if (data.type === 5) {
            // Checkbox group (multi-select)
            return (
              <div className="mb-3" key={data.id}>
                <Label htmlFor={data.id} className="form-label">
                  {data.dependencies ? (
                    <>
                      {data.question
                        .split(new RegExp(`(${data.dependencies.answer})`, "i"))
                        .map((part, index) =>
                          part.toLowerCase() ===
                          data.dependencies.answer.toLowerCase() ? (
                            <b key={index}>{part}</b>
                          ) : (
                            <span key={index}>{part}</span>
                          )
                        )}
                    </>
                  ) : (
                    data.question
                  )}
                </Label>
                <div
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    border: "1px solid whitesmoke",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {data.answers.map((answer, index) => (
                    <div className="form-check mb-2" key={index}>
                      <Input
                        name={data.id.toString()}
                        id={`${data.id}-${index}`}
                        className="form-check-input"
                        type="checkbox"
                        value={answer.value}
                        checked={(
                          questionValidation.values[data.id] || []
                        ).includes(answer.value)}
                        onChange={(e) => {
                          const valueArray = [
                            ...(questionValidation.values[data.id] || []),
                          ];
                          if (e.target.checked) {
                            valueArray.push(answer.value);
                          } else {
                            const index = valueArray.indexOf(answer.value);
                            if (index > -1) {
                              valueArray.splice(index, 1);
                            }
                          }
                          questionValidation.setFieldValue(data.id, valueArray);
                        }}
                        onBlur={questionValidation.handleBlur}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor={`${data.id}-${index}`}
                      >
                        {answer.label}
                      </Label>
                    </div>
                  ))}
                </div>
                {questionValidation.touched[data.id] &&
                  questionValidation.errors[data.id] && (
                    <FormFeedback
                      type="invalid"
                      className="d-block invalid-feedback"
                    >
                      {questionValidation.errors[data.id]}
                    </FormFeedback>
                  )}
              </div>
            );
          } else {
            return null;
          }
        })}
        <div className="mt-4 d-flex justify-content-between">
          <Button
            color="primary"
            className="btn btn-primary btn-sm"
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            Önceki
          </Button>
          <Button
            color="primary"
            className="btn btn-primary btn-sm"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            Sonraki
          </Button>
        </div>{" "}
        {currentPage === totalPages && (
          <div className="mt-4">
            <Button
              color="primary"
              className="btn btn-primary w-100"
              type="submit"
            >
              Devam Et
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default withRouter(Test);
