import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import baseApi from "../../api/baseApi";

import { Bounce, toast } from "react-toastify";

const initialState = {
  question: {},
  questionLoading: true,
  questionRefresh: true,

  availableTime: [],
  availableTimeLoading: true,
  availableTimeRefresh: true,

  isPatientPresent: {},
  checkPatientLoading: false,
};

export const checkPatientTCKN = createAsyncThunk(
  "user/checkPatientTCKN",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    const model = {
      name: values.name,
      surname: values.surname,
      tckn: values.tckn,
    };

    try {
      const response = await baseApi.post(`/User/CheckPatientTCKN`, model);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessages);
    }
  }
);

export const updatePatientReason = createAsyncThunk(
  "user/updatePatientReason",
  async (values) => {
    if (!values) {
      throw new Error("Veri bulunamadı!");
    }

    try {
      const response = await baseApi.put(`/User/UpdatePatientReason`, values);

      return response.data.result;
    } catch (error) {
      throw new Error(error.response.data.errorMessages);
    }
  }
);

export const fetchAvailableTime = createAsyncThunk(
  "user/fetchAvailableTime",
  async (date) => {
    if (date) {
      try {
        const response = await baseApi.get(
          `/User/GetAvailableTime?AppointmentDate=${date}`
        );

        return response.data.result;
      } catch (error) {
        throw new Error(error.response.data.errorMessage);
      }
    } else {
      try {
        const response = await baseApi.get(`/User/GetAvailableTime`);

        return response.data.result;
      } catch (error) {
        throw new Error(error.response.data.errorMessage);
      }
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkPatientTCKN.pending, (state, action) => {
        state.isPatientPresent = {};
        state.checkPatientLoading = true;
      })
      .addCase(checkPatientTCKN.fulfilled, (state, action) => {
        state.isPatientPresent = action.payload;
        state.checkPatientLoading = false;
      })
      .addCase(checkPatientTCKN.rejected, (state, action) => {
        state.isPatientPresent = {};
        state.checkPatientLoading = false;

        action.error.message &&
          toast.error(action.error.message, {
            style: { background: "#e30a17" },
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
      });

    builder
      .addCase(updatePatientReason.pending, (state, action) => {})
      .addCase(updatePatientReason.fulfilled, (state, action) => {})
      .addCase(updatePatientReason.rejected, (state, action) => {
        action.error.message &&
          toast.error(action.error.message, {
            style: { background: "#e30a17" },
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
      });

    builder
      .addCase(fetchAvailableTime.pending, (state, action) => {
        state.availableTime = [];
        state.availableTimeLoading = true;
      })
      .addCase(fetchAvailableTime.fulfilled, (state, action) => {
        state.availableTime = action.payload;
        state.availableTimeLoading = false;
      })
      .addCase(fetchAvailableTime.rejected, (state, action) => {
        state.availableTime = [];
        state.availableTimeLoading = false;
      });
  },
});

export default userSlice.reducer;
