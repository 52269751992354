import React, { useState } from "react";
import Draggable from "react-draggable";
import "./SwipeButton.css";

import verifyImage from "../../assets/images/dent/verify.svg";
import agreementImage from "../../assets/images/dent/agreement.svg";

// fontlar
import poppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";

// font import
Font.register({
  family: "Poppins",
  src: poppinsRegular,
});

// pdf tools
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  Image,
  pdf,
  PDFViewer,
} from "@react-pdf/renderer";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

import axios from "axios";

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 9, // Genel font boyutunu küçült
    fontFamily: "Poppins",
    position: "relative",
  },
  header: {
    textAlign: "center",
  },
  title: {
    fontSize: 14, // Başlık font boyutunu küçült
    fontWeight: "bold",
    marginBottom: 5,
    textTransform: "uppercase",
  },
  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 25,
    textTransform: "capitalize",
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 12, // Bölüm başlığı font boyutunu küçült
    fontWeight: "bold",
    marginTop: 5,
    marginBottom: 10,
    borderBottom: "1px solid #e63946",
    color: "#e63946",
  },
  twoColumnContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  column: {
    width: "48%",
  },
  paragraph: {
    fontSize: 9, // Paragraf font boyutunu küçült
    marginBottom: 10,
    lineHeight: 1.4, // Satır yüksekliğini küçült
  },
  boldParagraph: {
    fontSize: 9, // Paragraf font boyutunu küçült
    marginBottom: 10,
    lineHeight: 1.4, // Satır yüksekliğini küçült
    fontWeight: "bold",
  },
  answer: {
    fontSize: 9,
    textIndent: 10,
    marginTop: 2,
  },
  signature: {
    position: "absolute",
    bottom: 40,
    right: 40,
    fontSize: 10, // İmza font boyutunu küçült
    textAlign: "right",
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
  },
  footer: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 8, // Footer font boyutunu küçült
    color: "#888",
  },

  table: {
    display: "table",
    width: "auto",
    margin: "20px 0",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  tableCell: {
    margin: "auto",
  },

  image: {
    width: "100%",
    height: 150,
    marginBottom: 20,
  },
});

const types = {
  1: "Korkmuyorum",
  2: "Ortalam Korkuyorum",
  3: "Çok Korkuyorum",
};

const MyDocument = ({
  company,
  userData,
  questionData,
  date,
  isAgreementOne,
  isAgreementTwo,
  isAgreementThree,
}) => {
  return (
    <Document title="Sözleşme Detayları">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>{company}</Text>
          <Text>Sözleşme Başlığı</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>1. Diş Hekimi Ziyareti Sebebi</Text>
          {userData.reason.map((e, index) => {
            return (
              <Text style={styles.paragraph} key={index}>
                {index + 1}. {e}
              </Text>
            );
          })}
          <Text style={styles.sectionTitle}>2. Hasta Bilgileri</Text>
          <View style={styles.twoColumnContainer}>
            <View style={styles.column}>
              <Text style={styles.paragraph}>
                <Text style={styles.boldParagraph}>Ad Soyad:</Text>{" "}
                {userData.name} {userData.surname}
              </Text>
              <Text style={styles.paragraph}>
                Cep Telefonu: +90 {userData.phoneNumber}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.paragraph}>
                TC Kimlik No: {userData.tckn}
              </Text>
              <Text style={styles.paragraph}>
                E-posta: {userData.email ? userData.email : "-"}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.paragraph}>Cinsiyet: {userData.gender}</Text>
            </View>
          </View>
          {userData.gender == "Çocuk" && (
            <View style={styles.twoColumnContainer}>
              <View style={styles.column}>
                <Text style={styles.paragraph}>
                  Veli Ad Soyad: {userData.parentName} {userData.parentSurname}
                </Text>
              </View>
              <View style={styles.column}>
                <Text style={styles.paragraph}>
                  Veli TC Kimlik No: {userData.parentTckn}
                </Text>
              </View>
              <View style={styles.column}></View>
            </View>
          )}
          <Text style={styles.sectionTitle}>3. Diğer Bilgiler</Text>
          {questionData.slice(0, 1).map((item, index) => {
            return (
              <Text style={styles.paragraph} key={index}>
                <Text style={styles.boldParagraph}>- </Text> {item.question}
                {"\n"}
                <Text style={styles.answer}>{types[item.answer]}</Text>
              </Text>
            );
          })}
          {questionData.slice(1, 9).map((item, index) => (
            <Text style={styles.paragraph} key={index}>
              <Text style={styles.boldParagraph}>- </Text> {item.question}
              {"\n"}
              <Text style={styles.answer}>{item.answer}</Text>
            </Text>
          ))}
        </View>

        <View style={styles.signature}>
          <Text>{date}</Text>
          {userData.gender == "Çocuk" && (
            <Text>
              {userData.parentName} {userData.parentSurname}
            </Text>
          )}
          <Text>
            {userData.name} {userData.surname}
          </Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>3. Diğer Bilgiler</Text>
          {questionData.slice(10, 28).map((item, index) => (
            <Text style={styles.paragraph} key={index}>
              <Text style={styles.boldParagraph}>- </Text> {item.question}
              {"\n"}
              <Text style={styles.answer}>{item.answer}</Text>
            </Text>
          ))}
        </View>
        <View style={styles.signature}>
          <Text>{date}</Text>
          {userData.gender == "Çocuk" && (
            <Text>
              {userData.parentName} {userData.parentSurname}
            </Text>
          )}
          <Text>
            {userData.name} {userData.surname}
          </Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>3. Diğer Bilgiler</Text>
          {questionData.slice(29).map((item, index) => (
            <Text style={styles.paragraph} key={index}>
              <Text style={styles.boldParagraph}>- </Text> {item.question}
              {"\n"}
              <Text style={styles.answer}>{item.answer}</Text>
            </Text>
          ))}
        </View>
        <View style={styles.signature}>
          <Text>{date}</Text>
          {userData.gender == "Çocuk" && (
            <Text>
              {userData.parentName} {userData.parentSurname}
            </Text>
          )}
          <Text>
            {userData.name} {userData.surname}
          </Text>
        </View>
      </Page>

      {isAgreementOne && (
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.title}>DAVRANIŞ KURALLARI</Text>
            <Text style={styles.subTitle}>
              Ağız Boşluğunda Ameliyat Sonrası Davranış Kuralları
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>1. Kanama</Text>
            <View>
              <Text style={styles.paragraph}>
                Mendili yaklaşık 30 dakika boyunca ısırın. Yarayı kapatmak için
                bir protez kullanılmışsa, protezi 2-3 saat yerinde bırakın ve
                ardından kan kalıntılarını temizleyin. Bugün fiziksel efor sarf
                etmekten veya eğilmekten kaçının. Kanama devam ederse, temiz bir
                mendili 30 dakika daha ısırın. Kanama durmazsa lütfen derhal
                bizimle iletişime geçin.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>2. Anestezi</Text>
            <View>
              <Text style={styles.paragraph}>
                Lokal anestezinin yanı sıra tedavinin yarattığı stres ve
                gerginlik konsantre olma ve tepki verme yeteneğinizi
                etkileyebilir. Araç ve makine kullanırken lütfen bunu aklınızda
                bulundurun. Ameliyattan sonra eve bırakılmanız gerekmektedir.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>3. Yiyecek ve içecek</Text>
            <View>
              <Text style={styles.paragraph}>
                Anestezinin etkisi tamamen geçene kadar bekleyin. Kendinizi
                ısırarak veya yakarak yaralayabilirsiniz. Lütfen süt ürünleri ve
                baldan kaçının ve bugün kahve, siyah çay veya alkol
                içmemelisiniz. Önümüzdeki birkaç gün boyunca küçük tahıllı
                gıdalara (örneğin pirinç, bulgur vb.) da dikkat etmelisiniz.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>4. Sigara içmek</Text>
            <View>
              <Text style={styles.paragraph}>
                Sigara içmek yaraların iyileşmesini engeller, bu nedenle lütfen
                en azından bugün ve yarın sigara içmekten kaçının.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>5. Diş bakımı</Text>
            <View>
              <Text style={styles.paragraph}>
                Lütfen ağzınızı çok sık çalkalamayın, aksi takdirde yara
                stabilize olmayacaktır. Dişlerinizi düzenli olarak fırçalamaya
                devam edin, ancak önümüzdeki birkaç gün boyunca yara bölgesine
                fazla yüklenmeyin.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>6. Soğutma</Text>
            <View>
              <Text style={styles.paragraph}>
                Yara bölgesini üç saat boyunca sürekli olarak soğuk, nemli
                kompreslerle soğutun. Her seferinde 15 dakika soğutun ve
                ardından 5 dakika ara verin. Soğutma için buz kullanmayın!
              </Text>
            </View>
            <Text style={styles.sectionTitle}>7. Yara ağrısı</Text>
            <View>
              <Text style={styles.paragraph}>
                Lokal anestezinin etkisi geçtikten sonra hafif şişlik gibi ağrı
                da normaldir. Her ikisi de önümüzdeki birkaç gün içinde
                azalacaktır. İlk dönem için lütfen konsültasyondan sonra bir
                ağrı kesici alın. Ağrı azalmazsa, daha şiddetli hale gelirse
                veya sadece birkaç gün sonra ortaya çıkarsa, lütfen hemen bizi
                bilgilendirin.
              </Text>
            </View>
          </View>
          <View style={styles.signature}>
            <Text>{date}</Text>
            {userData.gender == "Çocuk" && (
              <Text>
                {userData.parentName} {userData.parentSurname}
              </Text>
            )}
            <Text>
              {userData.name} {userData.surname}
            </Text>
          </View>
        </Page>
      )}

      {isAgreementTwo && (
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.title}>ONAY – CERRAHİ MÜDAHLE</Text>
            <Text style={styles.subTitle}>Diş Ameliyatı İçin Onay</Text>
          </View>
          <View style={styles.section}>
            <View>
              <Text style={styles.paragraph}>
                Aşağıdaki diş cerrahisi prosedürünün üzerimde uygulanmasına izin
                veriyorum.
              </Text>
              <Text style={styles.paragraph}>
                Söz konusu cerrahi prosedür hakkında bilgilendirildim.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>
              Özellikle aşağıdaki hususlar bana açıklanmıştır:
            </Text>
            <View>
              <Text style={styles.paragraph}>
                Tüm cerrahi prosedürler, prosedür sırasında büyük özen
                gösterilmesine rağmen yara iyileşme bozukluğu, yara enfeksiyonu,
                kanama, hematom gibi komplikasyonlara yol açabilir.
              </Text>
              <Text style={styles.paragraph}>
                İşlem lokal anestezi altında gerçekleştirilir. Bu, anesteziye
                karşı intolerans reaksiyonlarına, enjeksiyon bölgesinde kanamaya
                ve enjeksiyon bölgesindeki sinirlerde hasara yol açabilir.
                Önceden var olan rahatsızlıklar ve genel hastalıklar iyileşme
                üzerinde olumsuz bir etkiye sahip olabilir. Bu nedenle, işlemden
                önce herhangi bir hastalığı ve ilgili ilaçları açıklamam
                gerektiğinin farkındayım.
              </Text>
              <Text style={styles.paragraph}>
                Anatomik koşullar nedeniyle, alt çene ameliyatları sırasında
                mandibular veya lingual sinirin yaralanması kesin olarak göz
                ardı edilemez. Bu durumda, dilde veya dudakta ve muhtemelen tat
                alma duyusunda geçici veya çok nadiren kalıcı bir bozulma veya
                his kaybı olabilir. Üst çene ameliyatı maksiller sinüsün
                açılmasıyla sonuçlanabilir. Bu durumda komplikasyonlar
                genellikle özellikle sıkı, muhtemelen plastik bir yara kapatma
                ile önlenir. Ancak, yara boşluğunda hematom oluşumu ve maksiller
                sinüs iltihabı kesin olarak göz ardı edilemez. Belirli
                prosedürler için maksiller sinüsün de prosedüre dahil edilmesi
                gerekir. Cerrahi prosedür ve olası komplikasyonlar (şişme,
                enfeksiyonlar, maksiller sinüzit) hakkında bilgilendirildim.
              </Text>
              <Text style={styles.paragraph}>
                Ameliyat sonrası kanama durumunda davranış, soğutma, ilaç alma,
                ağız hijyeni ve fiziksel dinlenme gibi işlem sonrası davranışlar
                hakkında bilgilendirildim ve talimat aldım. Olası
                komplikasyonlar ve bunun sonucunda ortaya çıkabilecek önemli yan
                etkiler de dahil olmak üzere planlanan prosedürüm hakkında
                verilen bilgileri anladığımı onaylıyorum. Benim için planlanan
                ameliyatın özel nitelikleri hakkında bilgilendirildim.
              </Text>
              <Text style={styles.paragraph}>
                İşlemden sonra araç kullanma kabiliyetimin sınırlı olabileceği
                konusunda bilgilendirildim. Bu nedenle bana bir bakıcının eşlik
                etmesi tavsiye edildi. Ayrıca, planlanan tıbbi prosedürün
                amacının ve seyrinin bana açıklandığını ve bu nedenle yeterince
                bilgilendirildiğimi onaylıyorum. İşlem sırasında komplikasyonlar
                nedeniyle veya tedavinin gerçekleştirilmemesi durumunda ortaya
                çıkabilecek bozukluklar hakkında kendimi bilgilendirme fırsatım
                oldu. Öngörülemeyen bulguların ortaya çıkması durumunda, tıbbi
                değerlendirmeye göre gerekli görülmesi halinde, önerilen
                prosedür kapsamı aşılabilir. Prosedür sırasında daha fazla
                açıklama mümkün olmasa ve prosedür kesintiye uğratılamasa veya
                ertelenemese bile.
              </Text>
              <Text style={styles.paragraph}>
                Kalan tüm sorularım cevaplandı.
              </Text>
            </View>
          </View>
          <View style={styles.signature}>
            <Text>{date}</Text>
            {userData.gender == "Çocuk" && (
              <Text>
                {userData.parentName} {userData.parentSurname}
              </Text>
            )}
            <Text>
              {userData.name} {userData.surname}
            </Text>
          </View>
        </Page>
      )}

      {isAgreementThree && (
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.title}>ONAY - IMPLANTASYON</Text>
            <Text style={styles.subTitle}>
              Yapay diş kökü implantasyonu için onay (İmplant)
            </Text>
          </View>
          <View style={styles.section}>
            <View>
              <Text style={styles.paragraph}>
                Yapay bir diş kökünün (implant) implantasyonu için onay
                veriyorum. İlgili cerrahi prosedür hakkında bilgilendirildim.
              </Text>
            </View>
            <Text style={styles.sectionTitle}>
              Özellikle aşağıdaki hususlar bana açıklanmıştır:
            </Text>
            <View>
              <Text style={styles.paragraph}>
                Her cerrahi işlemde olduğu gibi bir operasyon olan implantasyon,
                işlem sırasında gösterilen tüm özene rağmen yara iyileşme
                bozuklukları, yara enfeksiyonu, kanama ve hematom gibi
                komplikasyonlara yol açabilir. İşlem lokal anestezi altında
                gerçekleştirilir. Bu durum anesteziye karşı tahammülsüzlük
                reaksiyonlarına, enjeksiyon bölgesinde kanamaya ve enjeksiyon
                bölgesindeki sinirlerde hasara yol açabilir. Önceden var olan
                rahatsızlıklar ve genel hastalıklar implantın iyileşmesini
                olumsuz etkileyebilir. Bu nedenle, işlemden önce herhangi bir
                hastalığı ve buna bağlı ilaçları açıklamam gerektiğinin de
                farkındayım. İmplantlar genellikle kemikte çok iyi iyileşir.
                Ancak başarı garanti edilemeyeceği gibi implantın belirli bir
                süre boyunca yerinde kalması da mümkün değildir.
              </Text>
              <Text style={styles.paragraph}>
                Alt çeneye implantasyon durumunda, anatomik koşullar nedeniyle
                çene veya lingual sinirin yaralanması kesin olarak göz ardı
                edilemez. Bu durumda, dudakta veya dilde ve muhtemelen tat alma
                duyusunda geçici veya çok nadiren kalıcı bir bozulma veya his
                kaybı olabilir. Üst çeneye yapılan implantasyon maksiller
                sinüsün açılmasına neden olabilir. Kural olarak, plastik ve
                özellikle sıkı bir yara kapatma ile komplikasyonlar önlenir.
                Ancak, yara boşluğunda hematom oluşumu ve maksiller sinüsün
                olası iltihaplanması kesin olarak göz ardı edilemez.
              </Text>
              <Text style={styles.paragraph}>
                Belirli prosedürler için maksiller sinüsün de prosedüre dahil
                edilmesi gerekiyor. Cerrahi prosedür ve buna bağlı olası
                komplikasyonlar (şişme, enfeksiyonlar, maksiller sinüzit)
                hakkında bilgilendirildim. Ayrıca implantların çok iyi bir diş
                bakımı gerektirdiği konusunda da bilgilendirildim. Bir
                implantasyondan sonra düzenli kontroller gereklidir. İmplantın
                takılmasından sonraki ilk yıl içinde üç ayda bir kontrolün
                gerekli olduğu, ardından abutmentin iltihaplanması veya
                gevşemesinden kaynaklanan kayıpları önlemek için profesyonel diş
                temizliği (profilaksi) ile birlikte altı ayda bir düzenli
                kontrolün gerekli olduğu konusunda bilgilendirildim.
              </Text>
              <Text style={styles.paragraph}>
                Benim için planlanan prosedür hakkında, olası komplikasyonlar ve
                ortaya çıkan önemli bozukluklar da dahil olmak üzere verilen
                bilgileri anladığımı onaylıyorum. Benim için planlanan
                implantların özellikleri hakkında bilgilendirildim. Sonuç
                olarak, alternatif tedavi seçenekleri - implantlar olmadan da -
                tartışıldı.
              </Text>
              <Text style={styles.paragraph}>
                İşlemden sonra araç kullanma kabiliyetimin kısıtlanabileceği
                konusunda bilgilendirildim. Bu nedenle bana bir bakıcının eşlik
                etmesi önerildi. Planlanan tıbbi prosedürün amaçları ve seyri
                hakkında bilgilendirildiğimi onaylıyorum. İşlem sırasında
                komplikasyonlar nedeniyle veya tedavinin gerçekleştirilmemesi
                durumunda ortaya çıkabilecek bozukluklar hakkında kendimi
                bilgilendirme fırsatım oldu. Öngörülemeyen bulguların ortaya
                çıkması durumunda, tıbbi değerlendirmeye göre gerekli görülürse,
                işlem sırasında daha fazla bilgi verilemese ve işlem kesintiye
                uğratılamasa veya ertelenemese bile, önerilen işlem kapsamı
                aşılabilir.
              </Text>
              <Text style={styles.paragraph}>Tüm sorularım cevaplandı.</Text>
            </View>
          </View>
          <View style={styles.signature}>
            <Text>{date}</Text>
            {userData.gender == "Çocuk" && (
              <Text>
                {userData.parentName} {userData.parentSurname}
              </Text>
            )}
            <Text>
              {userData.name} {userData.surname}
            </Text>
          </View>
        </Page>
      )}
    </Document>
  );
};

// pdf tolls **

const SwipeButton = ({
  date,
  userData,
  questionApiData,
  questionData,
  setIsFinish,
  isAgreementOne,
  isAgreementTwo,
  isAgreementThree,
}) => {
  const [fillWidth, setFillWidth] = useState(0);
  const [swiped, setSwiped] = useState(false);
  const [modal_agreement, setmodal_agreement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function tog_agreement() {
    setmodal_agreement(!modal_agreement);
  }

  const handleDrag = (e, data) => {
    const maxWidth = 300;
    const newWidth = Math.min(data.x, maxWidth);
    setFillWidth(newWidth);
  };

  const handleStop = async (e, data) => {
    const maxWidth = 300;

    if (data.x > maxWidth - 61) {
      setIsLoading(true);

      const now = new Date();
      const isoDateTime = now.toISOString();

      try {
        const registrationResponse = await axios.post(
          "https://api.dijital-dental.com.tr/User/AddPatientInfo",
          {
            patientInfo: {
              id: "",
              name: userData.name,
              surname: userData.surname,
              tckn: userData.tckn,
              gender: userData.gender,
              parentName: userData.parentName,
              parentSurname: userData.parentSurname,
              parentTckn: userData.parentTckn,
              birthDate: userData.birthDate,
              pregnant: userData.pregnant,
              reason: userData.reason,
              email: userData.email,
              phoneNumber: userData.phoneNumber,
              appointmentDate: isoDateTime,
              pdfUrl: "",
              isAgreementOne: isAgreementOne,
              isAgreementTwo: isAgreementTwo,
              isAgreementThree: isAgreementThree,
              status: 3,
              doctorId: "string",
            },
            userAnswer: Object.values(questionApiData),
          }
        );

        const registrationId = registrationResponse.data.result;

        // PDF oluştur
        const doc = (
          <MyDocument
            company={"BlaBla Dent"}
            date={date}
            userData={userData}
            questionData={questionData}
            isAgreementOne={isAgreementOne}
            isAgreementTwo={isAgreementTwo}
            isAgreementThree={isAgreementThree}
          />
        );

        const asPdf = pdf([]);
        asPdf.updateContainer(doc);

        const blob = await asPdf.toBlob();

        // FormData oluştur
        const formData = new FormData();
        formData.append("file", blob, "contract.pdf");
        formData.append("id", registrationId);

        // PDF dosyasını ve kayıt ID'sini sunucuya gönderin
        await axios.post(
          `https://api.dijital-dental.com.tr/User/SavePdf?id=${registrationId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Eğer tüm işlemler başarılı ise setSwiped ve diğer işlemleri gerçekleştirin
        setSwiped(true);
        setFillWidth(maxWidth);
        setIsFinish(true);
      } catch (error) {
        setSwiped(false);
        setFillWidth(0);
        console.error("İşlem hatası:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setSwiped(false);
      setFillWidth(0);
    }
  };

  // Her harfin rengini hesapla
  const calculateTextColor = (index, total) => {
    const maxWidth = 240;
    const percentage = Math.min(fillWidth / maxWidth, 1);
    const charWidth = maxWidth / total;
    const charStart = (index * charWidth) / maxWidth;
    const charEnd = ((index + 1) * charWidth) / maxWidth;
    const charPercentage = Math.min(
      Math.max((percentage - charStart) / (charEnd - charStart), 0),
      1
    );

    // Başlangıç ve bitiş renkleri
    const startColor = { r: 220, g: 220, b: 220 }; // whitesmoke
    const endColor = { r: 69, g: 90, b: 100 }; // #455a64

    // Her harfin renk geçişi
    const red = Math.floor(
      startColor.r + (endColor.r - startColor.r) * charPercentage
    );
    const green = Math.floor(
      startColor.g + (endColor.g - startColor.g) * charPercentage
    );
    const blue = Math.floor(
      startColor.b + (endColor.b - startColor.b) * charPercentage
    );

    return `rgb(${red}, ${green}, ${blue})`;
  };

  const text = userData
    ? userData.gender == "Çocuk"
      ? `${userData.parentName} ${userData.parentSurname}`
      : `${userData.name} ${userData.surname}`
    : "";

  if (!userData) {
    return null;
  }

  return (
    <>
      {!swiped && (
        <>
          <div
            className="text-center d-sm-none"
            onClick={() => {
              document.getElementById("pdfDownloadLink").click();
            }}
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
                marginBottom: "10px",
                height: "64px",
              }}
            >
              <img
                src={agreementImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <p className="fs-11">Sözleşmeyi görüntüle</p>{" "}
            <PDFDownloadLink
              id="pdfDownloadLink"
              document={
                <MyDocument
                  company={"BlaBla Dent"}
                  date={date}
                  userData={userData}
                  questionData={questionData}
                  isAgreementOne={isAgreementOne}
                  isAgreementTwo={isAgreementTwo}
                  isAgreementThree={isAgreementThree}
                />
              }
              fileName={`${userData.name}_${userData.surname}`}
              style={{ display: "none" }}
            >
              {({ loading }) => (loading ? "PDF Yükleniyor..." : "PDF İndir")}
            </PDFDownloadLink>
          </div>

          <div
            className="text-center d-none d-sm-block"
            onClick={() => tog_agreement()}
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
                marginBottom: "10px",
                height: "64px",
              }}
            >
              <img
                src={agreementImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <p className="fs-11">Sözleşmeyi görüntüle</p>
          </div>
          <div className="mt-3 text-center fw-bold">
            <p className="fs-13 ">
              Yukarıda yer alan sözleşmeyi dikkatlice okudum, içeriğini tamamen
              anladım ve bu sözleşmenin tüm şartlarını kabul ediyorum. Sözleşme
              ile ilgili her türlü hukuki sonuçtan kendim sorumluyum.
            </p>
          </div>
        </>
      )}
      <div className="text-container mb-2">
        {[...text].map((char, index) => {
          return (
            <span
              key={index}
              style={{
                color:
                  swiped || char === " "
                    ? "#455a64"
                    : calculateTextColor(index, text.length),
              }}
            >
              {char === " " ? "\u00A0" : char}{" "}
            </span>
          );
        })}
      </div>
      {!swiped ? (
        isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Spinner animation="border" color="primary" />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div className="swipe-area">
              <div className="swipe-fill" style={{ width: fillWidth }} />
              <Draggable
                axis="x"
                bounds="parent"
                onDrag={handleDrag}
                onStop={handleStop}
                position={{ x: 0, y: 0 }}
              >
                <div className="draggable">
                  <span>{">>>"}</span>
                </div>
              </Draggable>
            </div>

            <div className="mt-2 text-center text-muted">
              <p className="fs-11">Onaylamak için lütfen kaydırınız.</p>
            </div>
          </div>
        )
      ) : (
        <div
          className="swipe-image-container mb-2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
            marginBottom: "10px",
            height: "144px",
          }}
        >
          <img
            src={verifyImage}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
          <div className="mt-3 text-center mb-2">
            <p className="fs-12">Kaydınız başarıyla oluşturuldu.</p>
          </div>
        </div>
      )}

      <Modal
        isOpen={modal_agreement}
        toggle={() => {
          tog_agreement();
        }}
        scrollable={true}
        id="agreementModal"
        size="lg"
      >
        <ModalHeader
          className="modal-title"
          id="agreementModalTitle"
          toggle={() => {
            tog_agreement();
          }}
        >
          Sözleşme Detayları
        </ModalHeader>
        <ModalBody>
          <PDFViewer width="100%" height="600px">
            <MyDocument
              company={"BlaBla Dent"}
              date={date}
              userData={userData}
              questionData={questionData}
              isAgreementOne={isAgreementOne}
              isAgreementTwo={isAgreementTwo}
              isAgreementThree={isAgreementThree}
            />
          </PDFViewer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SwipeButton;
