import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
  InputGroup,
} from "reactstrap";

// redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// slices
import {
  checkPatientTCKN,
  fetchAvailableTime,
  updatePatientReason,
} from "../../slices/apps/userSlice";

// router
import { Link, useLocation, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// görsel importlar
import doctorsImage from "../../assets/images/dent/doctors.svg";
import verifyImage from "../../assets/images/dent/verify.svg";

// diğer importlar
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactInputMask from "react-input-mask";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

// other import
import SwipeButton from "./SwipeButton";
import ImplantModal from "../PatientRegister/agreements/ImplantModal";
import RulesOfConductModal from "../PatientRegister/agreements/RulesOfConductModal";
import SurgicalInterventionModal from "../PatientRegister/agreements/SurgicalInterventionModal";

// data dosyaları

const questionList = [
  {
    id: 0,
    question: "Diş tedavisinden korkuyor musunuz?",
    required: true,
    type: 4,
    answers: null,
    dependencies: null,
  },
  {
    id: 1,
    question: "Hangi hastalıklara sahipsiniz?",
    required: false,
    type: 5,
    answers: [
      { value: "Kalp Hastalıkları", label: "Kalp Hastalıkları" },
      { value: "Şeker Hastalığı", label: "Şeker Hastalığı" },
      { value: "Tansiyon Sorunu", label: "Tansiyon Sorunu" },
      { value: "Epilepsi (Sara)", label: "Epilepsi (Sara)" },
      { value: "Ateşli Romatizma", label: "Ateşli Romatizma" },
      { value: "Eklem Romatizması", label: "Eklem Romatizması" },
      { value: "Guatr (Triod tabletleri)", label: "Guatr (Triod tabletleri)" },
      { value: "Kan Hastalıkları", label: "Kan Hastalıkları" },
      { value: "İlaç Alerjisi", label: "İlaç Alerjisi" },
      { value: "Zührevi Hastalık", label: "Zührevi Hastalık" },
      { value: "Sarılık", label: "Sarılık" },
      { value: "Astım", label: "Astım" },
      { value: "Saman Nezlesi", label: "Saman Nezlesi" },
      {
        value: "Böbrek Karaciğer Bozuklukları",
        label: "Böbrek Karaciğer Bozuklukları",
      },
      { value: "Akciğer Hastalıkları", label: "Akciğer Hastalıkları" },
      { value: "Sinüzit", label: "Sinüzit" },
      { value: "Anjina Pektoris", label: "Anjina Pektoris" },
      { value: "Bulaşıcı Hastalıklar", label: "Bulaşıcı Hastalıklar" },
      { value: "Alerjiler", label: "Alerjiler" },
      { value: "Felçler", label: "Felçler" },
    ],
    dependencies: null,
  },
  {
    id: 2,
    question: "Kalp hastalıkları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kalp Hastalıkları",
    },
  },
  {
    id: 3,
    question: "Kalp hastalıkları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kalp Hastalıkları",
    },
  },
  {
    id: 4,
    question: "Şeker hastalığı için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Şeker Hastalığı",
    },
  },
  {
    id: 5,
    question: "Şeker hastalığı için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Şeker Hastalığı",
    },
  },
  {
    id: 6,
    question: "Tansiyon sorunu için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Tansiyon Sorunu",
    },
  },
  {
    id: 7,
    question: "Tansiyon sorunu için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Tansiyon Sorunu",
    },
  },
  {
    id: 8,
    question: "Epilepsi için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Epilepsi (Sara)",
    },
  },
  {
    id: 9,
    question: "Epilepsi için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Epilepsi (Sara)",
    },
  },
  {
    id: 10,
    question: "Ateşli romatizma için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Ateşli Romatizma",
    },
  },
  {
    id: 11,
    question: "Ateşli romatizma için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Ateşli Romatizma",
    },
  },
  {
    id: 12,
    question: "Eklem romatizması için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Eklem Romatizması",
    },
  },
  {
    id: 13,
    question: "Eklem romatizması için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Eklem Romatizması",
    },
  },
  {
    id: 14,
    question: "Guatr için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Guatr (Triod tabletleri)",
    },
  },
  {
    id: 15,
    question: "Guatr için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Guatr (Triod tabletleri)",
    },
  },
  {
    id: 16,
    question: "Kan hastalıkları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kan Hastalıkları",
    },
  },
  {
    id: 17,
    question: "Kan hastalıkları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Kan Hastalıkları",
    },
  },
  {
    id: 18,
    question: "İlaç alerjisi için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "İlaç Alerjisi",
    },
  },
  {
    id: 19,
    question: "İlaç alerjisi için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "İlaç Alerjisi",
    },
  },
  {
    id: 20,
    question: "Zührevi hastalık için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Zührevi Hastalık",
    },
  },
  {
    id: 21,
    question: "Zührevi hastalık için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Zührevi Hastalık",
    },
  },
  {
    id: 22,
    question: "Sarılık için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sarılık",
    },
  },
  {
    id: 23,
    question: "Sarılık için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sarılık",
    },
  },
  {
    id: 24,
    question: "Astım için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Astım",
    },
  },
  {
    id: 25,
    question: "Astım için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Astım",
    },
  },
  {
    id: 26,
    question: "Saman nezlesi için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Saman Nezlesi",
    },
  },
  {
    id: 27,
    question: "Saman nezlesi için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Saman Nezlesi",
    },
  },
  {
    id: 28,
    question: "Böbrek karaciğer bozuklukları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Böbrek Karaciğer Bozuklukları",
    },
  },
  {
    id: 29,
    question: "Böbrek karaciğer bozuklukları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Böbrek Karaciğer Bozuklukları",
    },
  },
  {
    id: 30,
    question: "Akciğer hastalıkları için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Akciğer Hastalıkları",
    },
  },
  {
    id: 31,
    question: "Akciğer hastalıkları için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Akciğer Hastalıkları",
    },
  },
  {
    id: 32,
    question: "Sinüzit için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sinüzit",
    },
  },
  {
    id: 33,
    question: "Sinüzit için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Sinüzit",
    },
  },
  {
    id: 34,
    question: "Anjina pektoris için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Anjina Pektoris",
    },
  },
  {
    id: 35,
    question: "Anjina pektoris için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Anjina Pektoris",
    },
  },
  {
    id: 36,
    question: "Bulaşıcı hastalıklar için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Bulaşıcı Hastalıklar",
    },
  },
  {
    id: 37,
    question: "Bulaşıcı hastalıklar için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Bulaşıcı Hastalıklar",
    },
  },
  {
    id: 38,
    question: "Alerjiler için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Alerjiler",
    },
  },
  {
    id: 39,
    question: "Alerjiler için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Alerjiler",
    },
  },
  {
    id: 40,
    question: "Felçler için ilaç kullanıyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Felçler",
    },
  },
  {
    id: 41,
    question: "Felçler için tedavi görüyor musunuz?",
    required: true,
    type: 0,
    answers: null,
    dependencies: {
      questionId: 1,
      answer: "Felçler",
    },
  },
  {
    id: 42,
    question: "Lokal anestezi altında tedavi olmak ister misiniz?",
    required: true,
    type: 3,
    answers: [
      {
        value: "Hayır, yerel bir anestezi istemiyorum.",
        label: "Hayır, yerel bir anestezi istemiyorum.",
      },
      { value: "Evet, her zaman.", label: "Evet, her zaman." },
      {
        value: "Sadece ağır prosedürler için.",
        label: "Sadece ağır prosedürler için.",
      },
    ],
    dependencies: null,
  },
  {
    id: 48,
    question:
      "Geçmişte dişte yeterli anestezi sağlamak için birkaç anestezik enjeksiyonun gerekli olduğu deneyimini yaşadınız mı?",
    required: true,
    type: 0,
    answers: null,
    dependencies: null,
  },
];

const reasonList = [
  {
    value: "Diş ağrısı",
    label: "Diş ağrısı",
    agrements: ["isAgreementOne", "isAgreementTwo"],
  },
  {
    value: "Diş eti kanaması",
    label: "Diş eti kanaması",
    agrements: ["isAgreementOne", "isAgreementTwo"],
  },
  {
    value: "Muayene/Rutin kontrol",
    label: "Muayene/Rutin kontrol",
    agrements: ["isAgreementOne", "isAgreementTwo"],
  },
  {
    value: "İmplant/Protez",
    label: "İmplant/Protez",
    agrements: ["isAgreementOne", "isAgreementTwo", "isAgreementThree"],
  },
  {
    value: "Estetik",
    label: "Estetik",
    agrements: [],
  },
];

const animatedComponents = makeAnimated();
const QUESTIONS_PER_PAGE = 2;

const PatientEntrance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(0);

  const {
    isPatientPresent,
    availableTime,
    availableTimeLoading,
    checkPatientLoading,
  } = useSelector((state) => {
    return {
      isPatientPresent: state.user.isPatientPresent,
      availableTime: state.user.availableTime,
      availableTimeLoading: state.user.availableTimeLoading,
      checkPatientLoading: state.user.checkPatientLoading,
    };
  }, shallowEqual);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;

  // opt
  const [countdown, setCountdown] = useState(20);

  // anlaşmalar/sözleşmeler
  const [isAgreementOne, setIsAgreementOne] = useState(false);
  const [isAgreementTwo, setIsAgreementTwo] = useState(false);
  const [isAgreementThree, setIsAgreementThree] = useState(false);

  const [isFinish, setIsFinish] = useState(false);
  const [agreements, setAgreements] = useState([]); // Zorunlu sözleşmeler
  const [showRedirecting, setShowRedirecting] = useState(false);

  useEffect(() => {
    if (location.state && location.state.doctorCode) {
      setTab(0);
      setCountdown(0);
      setIsAgreementOne(false);
      setIsAgreementTwo(false);
      setIsAgreementThree(false);
      setShowRedirecting(false);
      setIsFinish(false);
      setAgreements([]);
    } else {
      navigate("/");
    }
  }, []);

  //#region tab0 kullanıcı kayıt kontrol
  const checkPatientValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      surname: "",
      tckn: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Lütfen adınızı giriniz")
        .min(2, "Lütfen adınızı giriniz"),
      surname: Yup.string()
        .required("Lütfen soyadınızı giriniz")
        .min(2, "Lütfen soyadınızı giriniz"),
      tckn: Yup.string()
        .length(11, "TC Kimlik numarası 11 haneli olmalıdır.")
        .matches(/^[1-9][0-9]{10}$/, "TC Kimlik numarası geçersiz.")
        .test(
          "is-valid-tc-no",
          "Geçersiz TC Kimlik numarası.",
          function (value) {
            if (!value) return false;

            const digits = value.split("").map(Number);
            const firstNineDigits = digits.slice(0, 9);

            const oddSum =
              firstNineDigits[0] +
              firstNineDigits[2] +
              firstNineDigits[4] +
              firstNineDigits[6] +
              firstNineDigits[8];
            const evenSum =
              firstNineDigits[1] +
              firstNineDigits[3] +
              firstNineDigits[5] +
              firstNineDigits[7];
            const tenthDigit = (oddSum * 7 - evenSum) % 10;
            if (tenthDigit !== digits[9]) return false;

            const sumOfFirstTen =
              firstNineDigits.reduce((sum, digit) => sum + digit, 0) +
              digits[9];
            const eleventhDigit = sumOfFirstTen % 10;
            return eleventhDigit === digits[10];
          }
        ),
    }),
    onSubmit: async (values) => {
      const resultAction = await dispatch(checkPatientTCKN(values));

      if (resultAction.meta.requestStatus === "fulfilled") {
        setTab(1);
      } else if (resultAction.meta.requestStatus === "rejected") {
        setShowRedirecting(true);
        const timer = setTimeout(() => {
          navigate("/new-patient");
        }, 5000);

        return () => clearTimeout(timer);
      }
    },
  });

  //#region ad soyad ilk harf büyük yapma fonksiyonları
  const capitalizeFirstLetter = (str) => {
    return str.replace(/^(.)|\s+(.)/g, function ($1) {
      return $1.toLocaleUpperCase("tr");
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = capitalizeFirstLetter(value);
    checkPatientValidation.setFieldValue(name, capitalizedValue);
  };
  //#endregion
  //#endregion

  //#region tab1 ziyaret sebebi ön kayıt
  const visitReasonValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      reason: [],
      healthStatusChange: "Hayır",
      pregnant: isPatientPresent["patientInfo"]?.pregnant || "Hayır",
    },
    validationSchema: Yup.object({
      reason: Yup.array()
        .of(Yup.string())
        .min(1, "Lütfen ziyaret sebebinizi seçiniz.")
        .required("Lütfen ziyaret sebebinizi seçiniz."),
      healthStatusChange: Yup.string().required("Zorunlu alan."),
      pregnant: Yup.string().test(
        "healthStatusChange-check",
        "Lütfen hamilelik durumunu seçiniz",
        function (value) {
          if (
            checkPatientValidation.values.healthStatusChange === "Evet" &&
            isPatientPresent["patientInfo"]?.gender == "Kadın"
          ) {
            return Yup.string()
              .required("Lütfen hamilelik durumunu seçiniz")
              .isValidSync(value);
          }
          return true;
        }
      ),
    }),

    onSubmit: async (values) => {
      if (
        visitReasonValidation.values.healthStatusChange === "Evet" ||
        agreements
          .filter((agreement) => !isPatientPresent["patientInfo"][agreement])
          .some(
            (e) =>
              e == "isAgreementOne" ||
              e == "isAgreementTwo" ||
              e == "isAgreementThree"
          )
      ) {
        setCurrentPage(1);
        setTab(2);
      } else {
        const model = {
          id: isPatientPresent["patientInfo"].id,
          reason: values.reason,
        };

        const resultAction = await dispatch(updatePatientReason(model));

        if (resultAction.meta.requestStatus === "fulfilled") {
          setTab(6);
          setIsFinish(true);
        }
      }
    },
  });

  //#endregion

  //#region tab2 sorular
  const [questions, setQuestions] = useState([]);
  const [questionsResult, setQuestionsResult] = useState([]);
  const [visibleQuestions, setVisibleQuestions] = useState(
    questionList.filter((q) => !q.dependencies).map((q) => q.id)
  );
  const [currentPage, setCurrentPage] = useState(1);

  const totalQuestions = visibleQuestions.length;
  const totalPages = Math.ceil(totalQuestions / QUESTIONS_PER_PAGE);

  const getValidationSchema = (visibleQuestions) => {
    return Yup.object(
      questionList.reduce((acc, question) => {
        const isQuestionVisible = visibleQuestions.includes(question.id);

        if (isQuestionVisible && question.required) {
          switch (question.type) {
            case 2:
              acc[question.id] = Yup.array()
                .of(Yup.string())
                .required("Zorunlu alan.");
              break;
            case 4:
              acc[question.id] = Yup.number().required("Zorunlu alan.");
              break;
            case 5:
              acc[question.id] = Yup.array()
                .of(Yup.string())
                .required("Zorunlu alan.");
              break;
            default:
              acc[question.id] = Yup.string().required("Zorunlu alan.");
              break;
          }
        } else {
          switch (question.type) {
            case 2:
              acc[question.id] = Yup.array().of(Yup.string());
              break;
            case 4:
              acc[question.id] = Yup.number();
              break;
            case 5:
              acc[question.id] = Yup.array().of(Yup.string());

              break;
            default:
              acc[question.id] = Yup.string();
              break;
          }
        }
        return acc;
      }, {})
    );
  };

  const questionValidation = useFormik({
    enableReinitialize: true,

    initialValues: questionList.reduce((acc, curr) => {
      const answerObj =
        isPatientPresent &&
        isPatientPresent["userAnswer"]?.find(
          (answer) => answer.questionId === curr.id
        );

      if (answerObj && answerObj.answer !== "-") {
        acc[curr.id] = answerObj.answer;
      } else {
        switch (curr.type) {
          case 2:
            acc[curr.id] = [];
            break;
          case 4:
            acc[curr.id] = 1;
            break;
          case 5:
            acc[curr.id] = [];
            break;
          case 0:
            acc[curr.id] = "Hayır";
            break;
          default:
            acc[curr.id] = "";
            break;
        }
      }

      return acc;
    }, {}),
    validationSchema: getValidationSchema(visibleQuestions),
    onSubmit: (values) => {
      const filteredValues = Object.keys(values).reduce((acc, key) => {
        const questionId = parseInt(key);
        if (visibleQuestions.includes(questionId)) {
          acc[key] = {
            id: 0,
            userId: "",
            questionId: questionId,
            answer: values[key],
          };
        } else {
          acc[key] = {
            id: 0,
            userId: "",
            questionId: questionId,
            answer: "-",
          };
        }
        return acc;
      }, {});

      const resultsWithQuestions = questionList.map((question) => {
        const value = filteredValues[question.id];

        return {
          question: question.question,
          answer: value
            ? Array.isArray(value.answer)
              ? value.answer.join(", ")
              : value.answer
            : "-",
        };
      });

      setQuestions(filteredValues);
      setQuestionsResult(resultsWithQuestions);

      setTab(
        agreements
          .filter((agreement) => !isPatientPresent["patientInfo"][agreement])
          .some(
            (e) =>
              e == "isAgreementOne" ||
              e == "isAgreementTwo" ||
              e == "isAgreementThree"
          )
          ? 3
          : 4
      );
    },
  });

  useEffect(() => {
    const updateVisibleQuestions = () => {
      const shouldQuestionBeVisible = (question) => {
        if (!question.dependencies) {
          return true;
        }

        const { questionId, answer } = question.dependencies;
        const parentAnswer = questionValidation.values[questionId];

        // Parent answer is an array
        if (Array.isArray(parentAnswer)) {
          return parentAnswer.includes(answer);
        }

        // Parent answer is a string
        return parentAnswer === answer;
      };

      const getVisibleQuestions = () => {
        const visible = new Set();

        const addVisibleQuestions = (questionId) => {
          const question = questionList.find((q) => q.id === questionId);
          if (!question || visible.has(questionId)) return;

          if (shouldQuestionBeVisible(question)) {
            visible.add(questionId);
            questionList.forEach((q) => {
              if (q.dependencies?.questionId === questionId) {
                addVisibleQuestions(q.id);
              }
            });
          }
        };

        questionList.forEach((q) => {
          if (!q.dependencies) {
            addVisibleQuestions(q.id);
          }
        });

        return Array.from(visible);
      };

      const newVisibleQuestions = getVisibleQuestions();
      setVisibleQuestions(newVisibleQuestions);
    };

    updateVisibleQuestions();
    questionValidation.validateForm();
  }, [questionValidation.values]);

  useEffect(() => {
    questionValidation.setErrors({});
    questionValidation.validateForm();
  }, [visibleQuestions]);

  const startIndex = (currentPage - 1) * QUESTIONS_PER_PAGE;
  const endIndex = Math.min(startIndex + QUESTIONS_PER_PAGE, totalQuestions);
  const questionsToDisplay = visibleQuestions.slice(startIndex, endIndex);

  //#endregion

  //#region tab 4 sms verify
  const otpValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      otpCode: "",
    },
    validationSchema: Yup.object({
      otpCode: Yup.string()
        .matches(/^\d{6}$/, "Doğrulama kodu 6 haneli bir sayı olmalıdır.")
        .required("Lütfen doğrulama kodunu giriniz."),
    }),

    onSubmit: async (values) => {
      setCurrentPage(1);
      setTab(5);
    },
  });

  const resendOTP = async () => {
    setCountdown(20);
  };

  useEffect(() => {
    let interval;

    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown]);
  //#endregion

  document.title = "Hasta Giriş Formu | BlaBla";
  if (showRedirecting || !(location.state && location.state.doctorCode)) {
    return (
      <React.Fragment>
        <Spinner
          className="position-absolute top-50 start-50"
          animation="border"
          color="primary"
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "#FDFDFD",
        }}
      >
        <div className="auth-page-content overflow-hidden pt-lg-5 z-0">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="g-0 align-items-center">
                    <Col
                      lg={6}
                      style={{
                        height: "620px",
                      }}
                      className="d-lg-block d-none"
                    >
                      <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        showArrows={false}
                        showStatus={false}
                        infiniteLoop={true}
                        className="slide"
                        id="qoutescarouselIndicators"
                      >
                        <div className="item" style={{ height: "620px" }}>
                          <img
                            src={doctorsImage}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </Carousel>
                    </Col>
                    <Col lg={6}>
                      {tab == 0 && (
                        <div className="p-lg-5 p-4">
                          <div>
                            <h5 style={{ color: "#455A64" }}>Hasta Giriş</h5>
                          </div>
                          <div className="mt-4">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                checkPatientValidation.handleSubmit();
                                return false;
                              }}
                              action="#"
                            >
                              <Row className="align-items-start">
                                <Col sm={12} md={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="name"
                                      className="form-label"
                                    >
                                      Ad
                                    </Label>
                                    <Input
                                      name="name"
                                      className="form-control"
                                      placeholder="Adınız"
                                      type="name"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={checkPatientValidation.handleBlur}
                                      value={
                                        checkPatientValidation.values.name || ""
                                      }
                                      invalid={
                                        checkPatientValidation.touched.name &&
                                        checkPatientValidation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {checkPatientValidation.touched.name &&
                                      checkPatientValidation.errors.name && (
                                        <FormFeedback type="invalid">
                                          {checkPatientValidation.errors.name}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col sm={12} md={6}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="surname"
                                      className="form-label"
                                    >
                                      Soyad
                                    </Label>
                                    <Input
                                      name="surname"
                                      className="form-control"
                                      placeholder="Soyadınız"
                                      type="surname"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={checkPatientValidation.handleBlur}
                                      value={
                                        checkPatientValidation.values.surname ||
                                        ""
                                      }
                                      invalid={
                                        checkPatientValidation.touched
                                          .surname &&
                                        checkPatientValidation.errors.surname
                                          ? true
                                          : false
                                      }
                                    />
                                    {checkPatientValidation.touched.surname &&
                                      checkPatientValidation.errors.surname && (
                                        <FormFeedback type="invalid">
                                          {
                                            checkPatientValidation.errors
                                              .surname
                                          }
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                              </Row>

                              <div className="mb-3">
                                <Label className="form-label" htmlFor="tckn">
                                  TC Kimlik No
                                </Label>
                                <InputGroup>
                                  <ReactInputMask
                                    mask="99999999999"
                                    maskChar=" "
                                    name="tckn"
                                    alwaysShowMask
                                    inputMode="numeric"
                                    type="tel"
                                    className={
                                      checkPatientValidation.touched.tckn &&
                                      checkPatientValidation.errors.tckn
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    onChange={
                                      checkPatientValidation.handleChange
                                    }
                                    value={checkPatientValidation.values.tckn}
                                  />
                                </InputGroup>
                                {checkPatientValidation.touched.tckn &&
                                  checkPatientValidation.errors.tckn && (
                                    <div
                                      type="invalid"
                                      className="d-block invalid-feedback"
                                    >
                                      {checkPatientValidation.errors.tckn}
                                    </div>
                                  )}
                              </div>

                              <div className="mt-4">
                                <Button
                                  color="danger"
                                  className="btn btn-danger w-100"
                                  type="submit"
                                  disabled={checkPatientLoading}
                                >
                                  {checkPatientLoading && (
                                    <Spinner size="sm" className="me-2" />
                                  )}
                                  {checkPatientLoading
                                    ? "Kontrol Ediliyor..."
                                    : "Devam Et"}
                                </Button>
                              </div>
                            </Form>
                          </div>
                          <div className="mt-4 text-center">
                            <p
                              className="mb-0 text-primary"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Ana Sayfaya dön.
                            </p>
                          </div>
                        </div>
                      )}
                      {tab == 1 && (
                        <div className="p-lg-5 p-4">
                          <div>
                            <h5 style={{ color: "#455A64" }}>
                              Hasta Kayıt Formu
                            </h5>
                          </div>
                          <div className="mt-4">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                visitReasonValidation.handleSubmit();
                                return false;
                              }}
                              action="#"
                            >
                              <div className="mb-3">
                                <Label
                                  htmlFor="reasonSelect"
                                  className="form-label"
                                >
                                  Diş hekimi ziyaretinizin sebebi nedir?
                                </Label>
                                {reasonList.map((reason) => (
                                  <div
                                    key={reason.value}
                                    className="form-check"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`reason-${reason.value}`}
                                      name="reason"
                                      value={reason.value}
                                      checked={(
                                        visitReasonValidation.values.reason ||
                                        []
                                      ).includes(reason.value)}
                                      onChange={(e) => {
                                        const selectedValues = [
                                          ...(visitReasonValidation.values
                                            .reason || []),
                                        ];

                                        if (e.target.checked) {
                                          selectedValues.push(reason.value);

                                          const newAgreements = new Set([
                                            ...agreements,
                                            ...reason.agrements,
                                          ]);
                                          setAgreements([...newAgreements]);
                                        } else {
                                          const index = selectedValues.indexOf(
                                            reason.value
                                          );
                                          if (index > -1) {
                                            selectedValues.splice(index, 1);
                                          }

                                          const remainingAgreements = reasonList
                                            .filter((r) =>
                                              selectedValues.includes(r.value)
                                            )
                                            .flatMap((r) => r.agrements);

                                          setAgreements([
                                            ...new Set(remainingAgreements),
                                          ]);
                                        }

                                        visitReasonValidation.setFieldValue(
                                          "reason",
                                          selectedValues
                                        );
                                      }}
                                      onBlur={visitReasonValidation.handleBlur}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor={`reason-${reason.value}`}
                                    >
                                      {reason.label}
                                    </Label>
                                  </div>
                                ))}
                                {visitReasonValidation.touched.reason &&
                                  visitReasonValidation.errors.reason && (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block invalid-feedback"
                                    >
                                      {visitReasonValidation.errors.reason}
                                    </FormFeedback>
                                  )}
                              </div>

                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="healthStatusChange"
                                >
                                  Son ziyaretinizden bu yana sağlık durumunuzda
                                  herhangi bir değişiklik oldu mu (hamilelik,
                                  yeni hastalıklar, ilaçlar, vb.)?
                                </Label>
                                <div className="form-check">
                                  <Input
                                    name="healthStatusChange"
                                    id="healthStatusChange1"
                                    className="form-check-input"
                                    type="radio"
                                    value="Evet"
                                    checked={
                                      visitReasonValidation.values
                                        .healthStatusChange === "Evet"
                                    }
                                    onChange={
                                      visitReasonValidation.handleChange
                                    }
                                    onBlur={visitReasonValidation.handleBlur}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="healthStatusChange1"
                                  >
                                    Evet
                                  </Label>
                                </div>
                                <div className="form-check">
                                  <Input
                                    name="healthStatusChange"
                                    id="healthStatusChange2"
                                    className="form-check-input"
                                    type="radio"
                                    value="Hayır"
                                    checked={
                                      visitReasonValidation.values
                                        .healthStatusChange === "Hayır"
                                    }
                                    onChange={
                                      visitReasonValidation.handleChange
                                    }
                                    onBlur={visitReasonValidation.handleBlur}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="healthStatusChange2"
                                  >
                                    Hayır
                                  </Label>
                                </div>
                              </div>

                              {visitReasonValidation.values
                                .healthStatusChange === "Evet" &&
                                isPatientPresent["patientInfo"]?.gender ==
                                  "Kadın" && (
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="pregnant"
                                    >
                                      Hamile misiniz?
                                    </Label>
                                    <div className="form-check">
                                      <Input
                                        name="pregnant"
                                        id="pregnant1"
                                        className="form-check-input"
                                        type="radio"
                                        value="Evet"
                                        checked={
                                          visitReasonValidation.values
                                            .pregnant === "Evet"
                                        }
                                        onChange={
                                          visitReasonValidation.handleChange
                                        }
                                        onBlur={
                                          visitReasonValidation.handleBlur
                                        }
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor="pregnant1"
                                      >
                                        Evet
                                      </Label>
                                    </div>
                                    <div className="form-check">
                                      <Input
                                        name="pregnant"
                                        id="pregnant2"
                                        className="form-check-input"
                                        type="radio"
                                        value="Hayır"
                                        checked={
                                          visitReasonValidation.values
                                            .pregnant === "Hayır"
                                        }
                                        onChange={
                                          visitReasonValidation.handleChange
                                        }
                                        onBlur={
                                          visitReasonValidation.handleBlur
                                        }
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor="pregnant2"
                                      >
                                        Hayır
                                      </Label>
                                    </div>
                                  </div>
                                )}

                              <div className="mt-4">
                                <Button
                                  color="danger"
                                  className="btn btn-danger w-100"
                                  type="submit"
                                >
                                  {visitReasonValidation.values
                                    .healthStatusChange === "Evet" ||
                                  agreements
                                    .filter(
                                      (agreement) =>
                                        !isPatientPresent["patientInfo"][
                                          agreement
                                        ]
                                    )
                                    .some(
                                      (e) =>
                                        e == "isAgreementOne" ||
                                        e == "isAgreementTwo" ||
                                        e == "isAgreementThree"
                                    )
                                    ? "Devam Et"
                                    : "Kayıt Oluştur"}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      )}
                      {tab == 2 && (
                        <div className="p-lg-5 p-4">
                          <div>
                            <h5 style={{ color: "#455A64" }}>
                              Hasta Kayıt Formu
                            </h5>
                          </div>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              questionValidation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mt-4">
                              {questionsToDisplay.map((questionId) => {
                                const data = questionList.find(
                                  (q) => q.id === questionId
                                );

                                if (!data) return null;

                                if (data.type === 0) {
                                  // Radio buttons
                                  return (
                                    <div className="mb-3" key={data.id}>
                                      <Label
                                        htmlFor={data.id}
                                        className="form-label"
                                      >
                                        {data.dependencies ? (
                                          <>
                                            {data.question
                                              .split(
                                                new RegExp(
                                                  `(${data.dependencies.answer})`,
                                                  "i"
                                                )
                                              )
                                              .map((part, index) =>
                                                part.toLowerCase() ===
                                                data.dependencies.answer.toLowerCase() ? (
                                                  <b key={index}>{part}</b>
                                                ) : (
                                                  <span key={index}>
                                                    {part}
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : (
                                          data.question
                                        )}
                                      </Label>
                                      <div className="form-check mb-2">
                                        <Input
                                          name={data.id.toString()}
                                          id={`${data.id}1`}
                                          className="form-check-input"
                                          type="radio"
                                          value="Evet"
                                          checked={
                                            questionValidation.values[
                                              data.id
                                            ] === "Evet"
                                          }
                                          onChange={
                                            questionValidation.handleChange
                                          }
                                          onBlur={questionValidation.handleBlur}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor={`${data.id}1`}
                                        >
                                          Evet
                                        </Label>
                                      </div>
                                      <div className="form-check">
                                        <Input
                                          name={data.id.toString()}
                                          id={`${data.id}2`}
                                          className="form-check-input"
                                          type="radio"
                                          value="Hayır"
                                          checked={
                                            questionValidation.values[
                                              data.id
                                            ] === "Hayır" ||
                                            questionValidation.values[
                                              data.id
                                            ] === undefined
                                          }
                                          onChange={
                                            questionValidation.handleChange
                                          }
                                          onBlur={questionValidation.handleBlur}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor={`${data.id}2`}
                                        >
                                          Hayır
                                        </Label>
                                      </div>
                                    </div>
                                  );
                                } else if (data.type === 1) {
                                  // Text input
                                  return (
                                    <div className="mb-3" key={data.id}>
                                      <Label
                                        htmlFor={data.id}
                                        className="form-label"
                                      >
                                        {data.dependencies ? (
                                          <>
                                            {data.question
                                              .split(
                                                new RegExp(
                                                  `(${data.dependencies.answer})`,
                                                  "i"
                                                )
                                              )
                                              .map((part, index) =>
                                                part.toLowerCase() ===
                                                data.dependencies.answer.toLowerCase() ? (
                                                  <b key={index}>{part}</b>
                                                ) : (
                                                  <span key={index}>
                                                    {part}
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : (
                                          data.question
                                        )}
                                      </Label>
                                      <Input
                                        name={data.id.toString()}
                                        id={data.id.toString()}
                                        className="form-control"
                                        placeholder={data.placeholder || ""}
                                        type="text"
                                        autoComplete="off"
                                        onChange={
                                          questionValidation.handleChange
                                        }
                                        onBlur={questionValidation.handleBlur}
                                        value={
                                          questionValidation.values[data.id] ||
                                          ""
                                        }
                                        invalid={
                                          questionValidation.touched[data.id] &&
                                          !!questionValidation.errors[data.id]
                                        }
                                      />
                                      {questionValidation.touched[data.id] &&
                                        questionValidation.errors[data.id] && (
                                          <FormFeedback type="invalid">
                                            {questionValidation.errors[data.id]}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  );
                                } else if (data.type === 2) {
                                  // Multi-select dropdown
                                  return (
                                    <div className="mb-3" key={data.id}>
                                      <Label
                                        htmlFor={data.id}
                                        className="form-label"
                                      >
                                        {data.dependencies ? (
                                          <>
                                            {data.question
                                              .split(
                                                new RegExp(
                                                  `(${data.dependencies.answer})`,
                                                  "i"
                                                )
                                              )
                                              .map((part, index) =>
                                                part.toLowerCase() ===
                                                data.dependencies.answer.toLowerCase() ? (
                                                  <b key={index}>{part}</b>
                                                ) : (
                                                  <span key={index}>
                                                    {part}
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : (
                                          data.question
                                        )}
                                      </Label>
                                      <Select
                                        name={data.id.toString()}
                                        options={data.answers}
                                        isMulti
                                        value={data.answers.filter((e) =>
                                          (
                                            questionValidation.values[
                                              data.id
                                            ] || []
                                          ).includes(e.value)
                                        )}
                                        onChange={(selectedOptions) => {
                                          const selectedValues =
                                            selectedOptions.map(
                                              (option) => option.value
                                            );
                                          questionValidation.setFieldValue(
                                            data.id,
                                            selectedValues
                                          );
                                        }}
                                        onBlur={questionValidation.handleBlur}
                                        isClearable
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        placeholder="Bir veya birden fazla seçebilirsiniz..."
                                        noOptionsMessage={() =>
                                          "Sonuç bulunamadı!"
                                        }
                                      />
                                      {questionValidation.touched[data.id] &&
                                        questionValidation.errors[data.id] && (
                                          <FormFeedback
                                            type="invalid"
                                            className="d-block invalid-feedback"
                                          >
                                            {questionValidation.errors[data.id]}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  );
                                } else if (data.type === 3) {
                                  // Single-select dropdown
                                  return (
                                    <div className="mb-3" key={data.id}>
                                      <Label
                                        htmlFor={data.id}
                                        className="form-label"
                                      >
                                        {data.dependencies ? (
                                          <>
                                            {data.question
                                              .split(
                                                new RegExp(
                                                  `(${data.dependencies.answer})`,
                                                  "i"
                                                )
                                              )
                                              .map((part, index) =>
                                                part.toLowerCase() ===
                                                data.dependencies.answer.toLowerCase() ? (
                                                  <b key={index}>{part}</b>
                                                ) : (
                                                  <span key={index}>
                                                    {part}
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : (
                                          data.question
                                        )}
                                      </Label>
                                      <Select
                                        name={data.id.toString()}
                                        options={data.answers}
                                        value={data.answers.find(
                                          (e) =>
                                            e.value ===
                                            questionValidation.values[data.id]
                                        )}
                                        onChange={(option) => {
                                          questionValidation.setFieldValue(
                                            data.id,
                                            option ? option.value : ""
                                          );
                                        }}
                                        onBlur={questionValidation.handleBlur}
                                        placeholder="Bir seçenek seçin..."
                                        noOptionsMessage={() =>
                                          "Sonuç bulunamadı!"
                                        }
                                      />
                                      {questionValidation.touched[data.id] &&
                                        questionValidation.errors[data.id] && (
                                          <FormFeedback
                                            type="invalid"
                                            className="d-block invalid-feedback"
                                          >
                                            {questionValidation.errors[data.id]}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  );
                                } else if (data.type === 4) {
                                  // Range slider
                                  return (
                                    <div className="mb-3" key={data.id}>
                                      <Label
                                        htmlFor={data.id}
                                        className="form-label d-flex flex-column"
                                      >
                                        {data.dependencies ? (
                                          <>
                                            {data.question
                                              .split(
                                                new RegExp(
                                                  `(${data.dependencies.answer})`,
                                                  "i"
                                                )
                                              )
                                              .map((part, index) =>
                                                part.toLowerCase() ===
                                                data.dependencies.answer.toLowerCase() ? (
                                                  <b key={index}>{part}</b>
                                                ) : (
                                                  <span key={index}>
                                                    {part}
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : (
                                          data.question
                                        )}
                                        <span className="text-muted fs-11">
                                          Derece:{" "}
                                          <b>
                                            {questionValidation.values[
                                              data.id
                                            ] == 1
                                              ? "1 Korkmuyorum"
                                              : questionValidation.values[
                                                  data.id
                                                ] == 2
                                              ? "2 Ortalama korkuyorum"
                                              : questionValidation.values[
                                                  data.id
                                                ] == 3
                                              ? "3 Çok korkuyorum"
                                              : questionValidation.values[
                                                  data.id
                                                ]}
                                          </b>
                                        </span>
                                      </Label>
                                      <input
                                        name={data.id.toString()}
                                        type="range"
                                        className="form-range"
                                        min="1"
                                        max="3"
                                        step="1"
                                        onChange={
                                          questionValidation.handleChange
                                        }
                                        onBlur={questionValidation.handleBlur}
                                        value={
                                          questionValidation.values[data.id] ||
                                          "1"
                                        }
                                      />
                                    </div>
                                  );
                                } else if (data.type === 5) {
                                  // Checkbox group (multi-select)
                                  return (
                                    <div className="mb-3" key={data.id}>
                                      <Label
                                        htmlFor={data.id}
                                        className="form-label"
                                      >
                                        {data.dependencies ? (
                                          <>
                                            {data.question
                                              .split(
                                                new RegExp(
                                                  `(${data.dependencies.answer})`,
                                                  "i"
                                                )
                                              )
                                              .map((part, index) =>
                                                part.toLowerCase() ===
                                                data.dependencies.answer.toLowerCase() ? (
                                                  <b key={index}>{part}</b>
                                                ) : (
                                                  <span key={index}>
                                                    {part}
                                                  </span>
                                                )
                                              )}
                                          </>
                                        ) : (
                                          data.question
                                        )}
                                      </Label>
                                      <div
                                        style={{
                                          maxHeight: "150px",
                                          overflowY: "auto",
                                          border: "1px solid whitesmoke",
                                          padding: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {data.answers.map((answer, index) => (
                                          <div
                                            className="form-check mb-2"
                                            key={index}
                                          >
                                            <Input
                                              name={data.id.toString()}
                                              id={`${data.id}-${index}`}
                                              className="form-check-input"
                                              type="checkbox"
                                              value={answer.value}
                                              checked={(
                                                questionValidation.values[
                                                  data.id
                                                ] || []
                                              ).includes(answer.value)}
                                              onChange={(e) => {
                                                const valueArray = [
                                                  ...(questionValidation.values[
                                                    data.id
                                                  ] || []),
                                                ];
                                                if (e.target.checked) {
                                                  valueArray.push(answer.value);
                                                } else {
                                                  const index =
                                                    valueArray.indexOf(
                                                      answer.value
                                                    );
                                                  if (index > -1) {
                                                    valueArray.splice(index, 1);
                                                  }
                                                }
                                                questionValidation.setFieldValue(
                                                  data.id,
                                                  valueArray
                                                );
                                              }}
                                              onBlur={
                                                questionValidation.handleBlur
                                              }
                                            />
                                            <Label
                                              className="form-check-label"
                                              htmlFor={`${data.id}-${index}`}
                                            >
                                              {answer.label}
                                            </Label>
                                          </div>
                                        ))}
                                      </div>
                                      {questionValidation.touched[data.id] &&
                                        questionValidation.errors[data.id] && (
                                          <FormFeedback
                                            type="invalid"
                                            className="d-block invalid-feedback"
                                          >
                                            {questionValidation.errors[data.id]}
                                          </FormFeedback>
                                        )}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                              <div className="mt-4 d-flex justify-content-between">
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-sm"
                                  disabled={currentPage === 1}
                                  onClick={() =>
                                    setCurrentPage((prev) => prev - 1)
                                  }
                                >
                                  Önceki
                                </Button>
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-sm"
                                  disabled={currentPage === totalPages}
                                  onClick={() =>
                                    setCurrentPage((prev) => prev + 1)
                                  }
                                >
                                  Sonraki
                                </Button>
                              </div>{" "}
                              {currentPage === totalPages && (
                                <div className="mt-4">
                                  <Button
                                    color="danger"
                                    className="btn btn-danger w-100"
                                    type="submit"
                                  >
                                    Devam Et
                                  </Button>
                                </div>
                              )}
                            </div>
                          </Form>
                          <div className="mt-4 text-center">
                            <p
                              className="mb-0 text-primary"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setTab(1);
                              }}
                            >
                              Önceki adıma dön.
                            </p>
                          </div>
                        </div>
                      )}
                      {tab == 3 && (
                        <div className="p-lg-5 p-4">
                          <div>
                            <h5 style={{ color: "#455A64" }}>
                              Hasta Kayıt Formu
                            </h5>
                          </div>

                          <div className="mt-4">
                            {agreements
                              .filter(
                                (agreement) =>
                                  !isPatientPresent["patientInfo"][agreement]
                              )
                              .some((e) => e == "isAgreementOne") && (
                              <RulesOfConductModal
                                date={formattedDate}
                                nameSurname={`${checkPatientValidation.values.name} ${checkPatientValidation.values.surname}`}
                                setIsAgreement={setIsAgreementOne}
                                isAgreement={isAgreementOne}
                              />
                            )}
                            {agreements
                              .filter(
                                (agreement) =>
                                  !isPatientPresent["patientInfo"][agreement]
                              )
                              .some((e) => e == "isAgreementTwo") && (
                              <SurgicalInterventionModal
                                date={formattedDate}
                                nameSurname={`${checkPatientValidation.values.name} ${checkPatientValidation.values.surname}`}
                                setIsAgreement={setIsAgreementTwo}
                                isAgreement={isAgreementTwo}
                              />
                            )}
                            {agreements
                              .filter(
                                (agreement) =>
                                  !isPatientPresent["patientInfo"][agreement]
                              )
                              .some((e) => e == "isAgreementThree") && (
                              <ImplantModal
                                date={formattedDate}
                                nameSurname={`${checkPatientValidation.values.name} ${checkPatientValidation.values.surname}`}
                                setIsAgreement={setIsAgreementThree}
                                isAgreement={isAgreementThree}
                              />
                            )}

                            <div className="mt-4">
                              <Button
                                color="danger"
                                className="btn btn-danger w-100"
                                disabled={
                                  (agreements
                                    .filter(
                                      (agreement) =>
                                        !isPatientPresent["patientInfo"][
                                          agreement
                                        ]
                                    )
                                    .some((e) => e == "isAgreementOne") &&
                                    !isAgreementOne) ||
                                  (agreements
                                    .filter(
                                      (agreement) =>
                                        !isPatientPresent["patientInfo"][
                                          agreement
                                        ]
                                    )
                                    .some((e) => e == "isAgreementTwo") &&
                                    !isAgreementTwo) ||
                                  (agreements
                                    .filter(
                                      (agreement) =>
                                        !isPatientPresent["patientInfo"][
                                          agreement
                                        ]
                                    )
                                    .some((e) => e == "isAgreementThree") &&
                                    !isAgreementThree)
                                }
                                onClick={() => {
                                  setTab(4);
                                }}
                              >
                                Devam Et
                              </Button>
                            </div>
                          </div>
                          <div className="mt-4 text-center">
                            <p
                              className="mb-0 text-primary"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setTab(2);
                              }}
                            >
                              Önceki adıma dön.
                            </p>
                          </div>
                        </div>
                      )}
                      {tab == 4 && (
                        <div className="p-lg-5 p-4">
                          <div className="mb-4">
                            <div className="avatar-lg mx-auto">
                              <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                <i className="ri-mail-line"></i>
                              </div>
                            </div>
                          </div>
                          <div className="text-muted text-center mx-lg-3">
                            <h4 className="">SMS Doğrulama 💬</h4>
                            <p>
                              Cep telefonunuza bir doğrulama kodu gönderdik.
                              Lütfen cep telefonunuza gelen kodu aşağıdaki alana
                              giriniz.
                            </p>
                          </div>

                          <div className="mt-4">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                otpValidation.handleSubmit();
                                return false;
                              }}
                              action="#"
                            >
                              <div className="mb-3">
                                <Label className="form-label" htmlFor="otpCode">
                                  Doğrulama Kodu
                                </Label>
                                <InputGroup>
                                  <ReactInputMask
                                    mask="999999"
                                    maskChar=" "
                                    name="otpCode"
                                    alwaysShowMask
                                    className={
                                      otpValidation.touched.otpCode &&
                                      otpValidation.errors.otpCode
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    onChange={otpValidation.handleChange}
                                    value={otpValidation.values.otpCode}
                                  />
                                </InputGroup>
                                {otpValidation.touched.otpCode &&
                                  otpValidation.errors.otpCode && (
                                    <div
                                      type="invalid"
                                      className="d-block invalid-feedback"
                                    >
                                      {otpValidation.errors.otpCode}
                                    </div>
                                  )}
                              </div>

                              <div className="mt-4">
                                <Button
                                  color="danger"
                                  className="btn btn-danger w-100"
                                  type="submit"
                                >
                                  Devam Et
                                </Button>
                              </div>
                            </Form>
                          </div>

                          <div className="mt-5 text-center">
                            <p className="mb-0">
                              Doğrulama kodunu alamadınız mı?{" "}
                              {countdown > 0 ? (
                                <span className="fw-semibold text-primary text-decoration-underline">
                                  {countdown} sn sonra tekrar gönder
                                </span>
                              ) : (
                                <span
                                  onClick={resendOTP}
                                  className="fw-semibold text-primary text-decoration-underline"
                                  style={{ cursor: "pointer" }}
                                >
                                  Tekrar gönder
                                </span>
                              )}
                            </p>
                          </div>

                          <div className="mt-4 text-center">
                            <p
                              className="mb-0 text-primary"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setTab(
                                  agreements
                                    .filter(
                                      (agreement) =>
                                        !isPatientPresent["patientInfo"][
                                          agreement
                                        ]
                                    )
                                    .some(
                                      (e) =>
                                        e == "isAgreementOne" ||
                                        e == "isAgreementTwo" ||
                                        e == "isAgreementThree"
                                    )
                                    ? 3
                                    : 2
                                );
                              }}
                            >
                              Önceki adıma dön.
                            </p>
                          </div>
                        </div>
                      )}
                      {tab == 5 && (
                        <div className="p-lg-5 p-4">
                          <SwipeButton
                            questionApiData={questions}
                            questionData={questionsResult}
                            setIsFinish={setIsFinish}
                            userData={{
                              ...isPatientPresent["patientInfo"],
                              ...visitReasonValidation.values,
                            }}
                            date={formattedDate}
                            isAgreementOne={
                              isPatientPresent["patientInfo"]["isAgreementOne"]
                                ? true
                                : isAgreementOne
                            }
                            isAgreementTwo={
                              isPatientPresent["patientInfo"]["isAgreementTwo"]
                                ? true
                                : isAgreementTwo
                            }
                            isAgreementThree={
                              isPatientPresent["patientInfo"][
                                "isAgreementThree"
                              ]
                                ? true
                                : isAgreementThree
                            }
                          />
                          {isFinish ? (
                            <div className="mt-5 text-center">
                              <p
                                className="mb-0 text-primary"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/");
                                }}
                              >
                                Ana Sayfaya dön.
                              </p>
                            </div>
                          ) : (
                            <div className="mt-5 text-center">
                              <p
                                className="mb-0 text-primary"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setTab(0);
                                }}
                              >
                                Başa dön.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {tab == 6 && (
                        <div className="p-lg-5 p-4">
                          <div
                            className="swipe-image-container mb-2"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyItems: "center",
                              alignItems: "center",
                              marginBottom: "10px",
                              height: "144px",
                            }}
                          >
                            <img
                              src={verifyImage}
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <div className="mt-3 text-center mb-2">
                              <p className="fs-12">
                                Kaydınız başarıyla oluşturuldu.
                              </p>
                            </div>
                          </div>
                          {isFinish && (
                            <div className="mt-5 text-center">
                              <p
                                className="mb-0 text-primary"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigate("/");
                                }}
                              >
                                Ana Sayfa
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0" style={{ color: "#808080" }}>
                    &copy; {new Date().getFullYear()} BlaBla{" "}
                    <i className="mdi mdi-heart text-danger"></i> by Yusuf Ayaz
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PatientEntrance);
