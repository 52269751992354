import { combineReducers } from "redux";

// Front
import LayoutReducer from "./other/layouts/reducer";

import questionSlice from "./apps/questionSlice";
import userSlice from "./apps/userSlice";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  question: questionSlice,
  user: userSlice,
});

export default rootReducer;
